import './ReportsSuccess.scss'

import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import { Notification } from 'shared'
import { Dispatch, bindActionCreators } from 'redux'
import { LayoutChangeActiveMenu } from '../../redux/layout/layout.actions'
import { connect } from 'react-redux'

interface RegisterProps {
  setTitle: (newTitle: string) => void
  setActiveMenu: (activeMenu: string) => void
}

export const RegistrationSuccess = (props: RegisterProps) => {
  // update title
  props.setTitle('Online Portal')
  props.setActiveMenu('login')

  return (
    <>
      <Notification type="success">Registration Successful</Notification>

      <Typography variant="h6" align="left" className="register-success">
        Your registration details have been sent to your email address. If the
        email is not received, please&nbsp;
        <MuiLink underline="always" href={`https://fisherfunds.co.nz/contact`}>
          contact us
        </MuiLink>
        .
      </Typography>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  actions: {
    setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
  },
})

export default connect(null, mapDispatchToProps, null)(RegistrationSuccess)
