import * as React from 'react'
import './ContactDetailsForm.scss'

import { Address, PhoneEmailDetails } from '../../common/addresses.types'
import { State } from '../../redux/state/state.model'
import { Countries, CountryDropdownSet } from '../../common/country-list'
import { bindActionCreators, Dispatch } from 'redux'
import {
  UserActions,
  UserUpdateDetailsAction,
  UserContactDetailsType,
  UserUpdateSavingStatusAction,
} from '../../redux/user/user.actions'
import { connect } from 'react-redux'

import DetailsFormAlert from '../details-form-alert/DetailsFormAlert'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Notification } from 'shared'

import Alert from 'react-s-alert'
import {
  AuthorizationFormValues,
  createRequestAuthorizer,
} from '../authorization-modal/use-authorized-request'
import { Button } from '../clickable/button/Button'

export enum SavingStatus {
  NONE,
  SAVING,
  SAVED,
  ERROR,
}

export type AddressFinderObj = {
  address_line_1: () => string
  address_line_2: () => string
  suburb: () => string
  city: () => string
  postcode: () => string
}

type SavingState = { savingStatus: SavingStatus }
type ContactDetailsFormState = Address &
  PhoneEmailDetails & {
    originalContactDetails: PhoneEmailDetails
    originalAddressDetails: Address
    fieldErrors?: ValidationErrors
    hasError?: boolean
    errorMessage?: string
    executingHandler: (
      formValues: { password: string } | { pin: string }
    ) => void
    modalOpen: boolean
  }

interface ContactDetailsFormProps
  extends State,
    SavingState,
    Address,
    PhoneEmailDetails {
  userid: string
  hasError: boolean
  errorMessage: string
  saveContactDetails?: (
    details: UserContactDetailsType,
    ffmUserId: string
  ) => void
  updateSavingStatus?: (status: SavingStatus) => void
}

interface ValidationErrors {
  [key: string]: string | string[]
}

declare global {
  interface Window {
    AddressFinder: any
  }
}

const ErrorMessage: any = ({
  field,
  errors,
}: {
  field: string
  errors: ValidationErrors
}) => {
  if (errors[field]) {
    const content = errors[field]
    if (Array.isArray(content)) {
      return content.map((message) => (
        <span className="has-error">{message}</span>
      ))
    } else {
      return <span className="has-error">{content}</span>
    }
  } else {
    return null
  }
}

export class ContactDetailsForm extends React.Component<
  ContactDetailsFormProps,
  ContactDetailsFormState
> {
  widget: any
  script: HTMLScriptElement | undefined

  constructor(props: ContactDetailsFormProps) {
    super(props)

    this.submit = this.submit.bind(this)
    this.reset = this.reset.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCountryChange = this.handleCountryChange.bind(this)
    this.handleEmailOnBlur = this.handleEmailOnBlur.bind(this)

    this.widget = null

    const contactDetails = {
      businessPhoneNumber: props.businessPhoneNumber || '',
      homePhoneNumber: props.homePhoneNumber || '',
      mobilePhoneNumber: props.mobilePhoneNumber || '',
      email: props.email || '',
    }

    const addressDetails = {
      addressLine1: props.addressLine1 || '',
      addressLine2: props.addressLine2 || '',
      suburb: props.suburb || '',
      city: props.city || '',
      country: props.country || '',
      postCode: props.postCode || '',
    }

    this.state = {
      ...contactDetails,
      ...addressDetails,
      originalContactDetails: {
        ...contactDetails,
      },
      originalAddressDetails: {
        ...addressDetails,
      },
      fieldErrors: {},
      hasError: false,
      errorMessage: null,
      executingHandler: null,
      modalOpen: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ContactDetailsFormProps) {
    if (this.hasAddressPropsChanged(nextProps)) {
      this.setState({
        addressLine1: nextProps.addressLine1 || '',
        addressLine2: nextProps.addressLine2 || '',
        suburb: nextProps.suburb || '',
        city: nextProps.city || '',
        postCode: nextProps.postCode || '',
        country: nextProps.country || '',
      })
    }

    if (nextProps.savingStatus === SavingStatus.SAVED) {
      const contactDetails = {
        businessPhoneNumber: nextProps.businessPhoneNumber || '',
        homePhoneNumber: nextProps.homePhoneNumber || '',
        mobilePhoneNumber: nextProps.mobilePhoneNumber || '',
        email: nextProps.email || '',
      }

      const addressDetails = {
        addressLine1: nextProps.addressLine1 || '',
        addressLine2: nextProps.addressLine2 || '',
        suburb: nextProps.suburb || '',
        city: nextProps.city || '',
        country: nextProps.country || '',
        postCode: nextProps.postCode || '',
      }

      this.setState({
        ...contactDetails,
        ...addressDetails,
        originalContactDetails: {
          ...contactDetails,
        },
        originalAddressDetails: {
          ...addressDetails,
        },
      })
    }

    // Hide notification banner after 8 seconds.
    setTimeout(() => {
      this.props.updateSavingStatus(SavingStatus.NONE)
    }, 8000)
  }

  // The following four functions have been based off the example code in the
  // documentation of Address Finder.
  // https://codepen.io/addressfinder/project/editor/AqaRng

  componentDidMount() {
    if (this.script) return
    this.script = document.createElement('script')
    this.script.src = process.env.REACT_APP_ADDRESS_FINDER_SCRIPT_URL!
    this.script.async = true
    this.script.onload = this.loadWidget
    document.body.appendChild(this.script)
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.destroy()
      this.widget = null
      document.body.removeChild(this.script)
    }
  }

  loadWidget = () => {
    this.widget = new window.AddressFinder.Widget(
      document.getElementById('addressLine1'),
      process.env.REACT_APP_ADDRESS_FINDER_API_KEY,
      'NZ'
    )
    this.widget.on('result:select', (fullAddress: any, metaData: any) => {
      this.setAddress(
        new window.AddressFinder.NZSelectedAddress(fullAddress, metaData)
      )
    })
  }

  setAddress(addressFinderObj: AddressFinderObj) {
    this.setState({
      addressLine1: addressFinderObj.address_line_1(),
      addressLine2: addressFinderObj.address_line_2(),
      suburb: addressFinderObj.suburb(),
      city: addressFinderObj.city(),
      postCode: addressFinderObj.postcode(),
      country: 'NZ', // Because only NZ addresses can be selected always change the country to NZ.
    })
  }

  setEmptyAddress() {
    this.setState({
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      city: '',
      postCode: '',
      country: 'NZ', // Because only NZ addresses can be selected always change the country to NZ.
    })
  }

  hasContactChanges() {
    const changeFields = this.getChangedFields(this.state)
    return Object.keys(changeFields).length > 0
  }

  submit(formValues: AuthorizationFormValues) {
    const changeFields = this.getChangedFields(this.state)
    if (Object.keys(changeFields).length > 0) {
      const fieldErrors = this.validateFields()
      if (Object.keys(fieldErrors).length === 0) {
        Alert.closeAll()

        // We remove spaces from phone numbers fields.
        const fields = {}
        Object.keys(changeFields).forEach((name) => {
          const value = changeFields[name]
          const isPhoneNumber = /phonenumber/i.test(name)
          fields[name] = isPhoneNumber ? value.replaceAll(' ', '') : value
        })
        this.props.saveContactDetails(
          {
            ...fields,
            ...formValues,
          },
          this.props.userid
        )
      }

      this.setState({
        fieldErrors,
        modalOpen: false,
      })
    }
  }

  reset() {
    const resetState = {
      ...this.state.originalContactDetails,
      ...this.state.originalAddressDetails,
      fieldErrors: {},
    }

    this.setState(resetState)
  }

  handleEmailOnBlur(e: any) {
    const target = e.target
    const errors = this.clearFieldsError(target.id, this.state.fieldErrors)
    this.validateEmail(errors, target.id, target.value)
    this.setState({
      fieldErrors: errors,
    })
  }

  handleInputChange(e: any) {
    const target = e.target as HTMLInputElement
    this.validateFieldsOnChange(target)
    this.setState({
      [target.id]: target.value,
    } as any)
  }

  handleCountryChange(event: React.ChangeEvent<{ value: unknown }>) {
    this.setState({
      country: event.target.value as string,
    })
  }

  renderActionButtons(disabled: boolean) {
    const [authElements, protectFunction] = createRequestAuthorizer(
      this.state.modalOpen,
      () => {
        this.setState({ modalOpen: true })
      },
      () => {
        this.setState({ modalOpen: false })
      },
      (handler) => {
        this.setState({ executingHandler: handler })
      },
      this.state.executingHandler
    )
    return (
      <div className="action-buttons">
        {authElements}
        <Button
          disabled={disabled || !this.hasContactChanges()}
          onClick={protectFunction(() => this.submit)}
        >
          Confirm Update
        </Button>
        <Button variant="link" onClick={this.reset}>
          Reset
        </Button>
      </div>
    )
  }

  render() {
    const disabled =
      this.props.savingStatus === SavingStatus.SAVING || this.props.isLoading
    const countries = Countries.getCountryDropdownSet()
    const showInfoBanner =
      this.props.savingStatus === SavingStatus.SAVED ||
      this.props.savingStatus === SavingStatus.ERROR

    const ClearButton = () => (
      <div
        className="address-clear-button"
        onClick={() => this.setEmptyAddress()}
        onKeyPress={this.setEmptyAddress}
        role="button"
        tabIndex={0}
      >
        ✕<span className="text">Clear</span>
      </div>
    )

    return (
      <div className="contact-details-form-component">
        <Notification className="mb-md">
          Please note — if you have requested a change to your contact details
          within the past 3 business days, do not update your details again here
          whilst this is being processed.
        </Notification>

        <DetailsFormAlert
          isVisible={showInfoBanner}
          hasSucceed={this.props.savingStatus === SavingStatus.SAVED}
          successMessage={
            'Your contact details have been changed successfully.'
          }
          hasError={this.props.hasError}
          errorMessage={this.props.errorMessage}
          onClose={() => {}}
        />

        <form className="form base-form">
          <Grid container spacing={3}>
            <Grid item>
              <Typography component="h1" variant="h6">
                Contact
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="base-field-group">
                    <TextField
                      label="Email"
                      name="email"
                      id="email"
                      type="email"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.email}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your email address"
                      helperText={
                        this.getFieldErrorClassName('email')
                          ? 'Email field is a required field'
                          : ''
                      }
                      error={
                        this.getFieldErrorClassName('email') ? true : false
                      }
                    />
                  </div>
                  <ErrorMessage field="email" errors={this.state.fieldErrors} />
                </Grid>
                <Grid item xs={6}>
                  <div className="base-field-group">
                    <TextField
                      label="Mobile phone number (optional)"
                      name="mobilePhoneNumber"
                      id="mobilePhoneNumber"
                      type="tel"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.mobilePhoneNumber}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your mobile phone number (optional)"
                      helperText={
                        this.getFieldErrorClassName('mobilePhoneNumber')
                          ? 'There is an error with mobile number'
                          : ''
                      }
                      error={
                        this.getFieldErrorClassName('mobilePhoneNumber')
                          ? true
                          : false
                      }
                    />
                  </div>
                  <ErrorMessage
                    field="mobilePhoneNumber"
                    errors={this.state.fieldErrors}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div className="base-field-group">
                    <TextField
                      label="Home phone number (optional)"
                      name="homePhoneNumber"
                      id="homePhoneNumber"
                      type="tel"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.homePhoneNumber}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your home phone number (optional)"
                      helperText={
                        this.getFieldErrorClassName('homePhoneNumber')
                          ? 'There is an error with home phone number'
                          : ''
                      }
                      error={
                        this.getFieldErrorClassName('homePhoneNumber')
                          ? true
                          : false
                      }
                    />
                  </div>
                  <ErrorMessage
                    field="homePhoneNumber"
                    errors={this.state.fieldErrors}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div className="base-field-group">
                    <TextField
                      label="Business phone number (optional)"
                      name="businessPhoneNumber"
                      id="businessPhoneNumber"
                      type="tel"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.businessPhoneNumber}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your business phone number (optional)"
                      helperText={
                        this.getFieldErrorClassName('businessPhoneNumber')
                          ? 'There is an error with business phone number'
                          : ''
                      }
                      error={
                        this.getFieldErrorClassName('businessPhoneNumber')
                          ? true
                          : false
                      }
                    />
                  </div>
                  <ErrorMessage
                    field="businessPhoneNumber"
                    errors={this.state.fieldErrors}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography component="h1" variant="h6" className="address-title">
                Address
                <ClearButton></ClearButton>
              </Typography>

              <div className="base-field-group address-main">
                <TextField
                  label="Address"
                  name="addressLine1"
                  id="addressLine1"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={this.state.addressLine1}
                  onChange={this.handleInputChange}
                  disabled={disabled}
                  placeholder="Enter your address"
                />
              </div>
              <ErrorMessage
                field="addressLine1"
                errors={this.state.fieldErrors}
              />

              <div className="base-field-group">
                <TextField
                  label="Address 2 (optional)"
                  name="addressLine2"
                  id="addressLine2"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={this.state.addressLine2}
                  onChange={this.handleInputChange}
                  disabled={disabled}
                  placeholder="Enter your address 2 (optional)"
                />
              </div>

              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <div className="base-field-group">
                    <TextField
                      label="Suburb (optional)"
                      name="suburb"
                      id="suburb"
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.suburb}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your suburb"
                    />
                  </div>
                  <ErrorMessage
                    field="suburb"
                    errors={this.state.fieldErrors}
                  />
                </Grid>

                <Grid item xs={4}>
                  <div className="base-field-group">
                    <TextField
                      label="City"
                      name="city"
                      id="city"
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.city}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your city"
                    />
                  </div>
                  <ErrorMessage field="city" errors={this.state.fieldErrors} />
                </Grid>

                <Grid item xs={3}>
                  <div className="base-field-group">
                    <TextField
                      label="Post code"
                      name="postCode"
                      id="postCode"
                      type="text"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={this.state.postCode}
                      onChange={this.handleInputChange}
                      disabled={disabled}
                      placeholder="Enter your post code"
                    />
                  </div>
                  <ErrorMessage
                    field="postCode"
                    errors={this.state.fieldErrors}
                  />
                </Grid>
              </Grid>

              <div className="base-field-group country-field">
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="country-select-label">
                    Country
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    label="Country"
                    labelId="country-select-label"
                    variant="outlined"
                    value={this.state.country}
                    disabled={disabled}
                    onChange={this.handleCountryChange}
                    inputProps={{
                      name: 'country',
                      id: 'country-select-label',
                    }}
                  >
                    {countries.map((country: CountryDropdownSet) => {
                      return (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>

          {this.renderActionButtons(disabled)}
        </form>
      </div>
    )
  }

  private static contactFields = [
    'businessPhoneNumber',
    'homePhoneNumber',
    'mobilePhoneNumber',
    'email',
  ]
  private static addressFields = [
    'addressLine1',
    'addressLine2',
    'suburb',
    'city',
    'postCode',
    'country',
  ]

  private getChangedFields(data: ContactDetailsFormState) {
    const changeFields = {}

    ContactDetailsForm.contactFields.forEach((name) => {
      if (
        this.areTrimmedValuesDifferent(
          data[name],
          data.originalContactDetails[name]
        )
      ) {
        changeFields[name] = data[name]
      }
    })

    if (this.hasAddressChanged(data)) {
      ContactDetailsForm.addressFields.forEach((name) => {
        changeFields[name] = data[name] || ''
      })
    }

    return changeFields
  }

  private areTrimmedValuesDifferent(a: string, b: string) {
    a = a || ''
    b = b || ''
    return a.trim() !== b.trim()
  }

  private hasAddressChanged(data: ContactDetailsFormState) {
    return ContactDetailsForm.addressFields.some((name) =>
      this.areTrimmedValuesDifferent(
        data[name],
        data.originalAddressDetails[name]
      )
    )
  }

  private hasAddressPropsChanged(nextProps: ContactDetailsFormProps) {
    return ContactDetailsForm.addressFields.some((name) =>
      this.areTrimmedValuesDifferent(this.props[name], nextProps[name])
    )
  }

  private validatePhoneNumber(
    errors: ValidationErrors,
    field: string,
    value: string,
    message?: string
  ) {
    const phone = value ? value.replaceAll(' ', '') : ''
    const isValid = /^\+?[0-9]{0,15}$/.test(phone)
    if (!isValid) {
      errors[field] = message || 'Invalid phone number'
    }
  }

  private validateEmail(
    errors: ValidationErrors,
    field: string,
    value: string
  ) {
    value = value || ''
    if (value === '') {
      errors[field] = 'Email is required'
    } else if (!/^\S+@\S+\.\S+$/.test(value.trim())) {
      errors[field] = 'Invalid email format'
    }
  }

  private validatePostCode(
    errors: ValidationErrors,
    field: string,
    value: string
  ) {
    value = value || ''
    if (value === '') {
      errors[field] = 'Post Code is required'
    } else if (/\D/.test(value.trim())) {
      errors[field] = [
        'Post Code format is invalid',
        'Post Code must be at least 4 characters',
      ]
    } else if (!/^\d{4,}$/.test(value.trim())) {
      errors[field] = 'Post Code must be at least 4 characters'
    }
  }

  private validateMandatory(
    errors: ValidationErrors,
    field: string,
    value: string,
    label: string
  ) {
    value = value || ''
    if (value.trim() === '') {
      errors[field] = `${label} is required`
    }
  }

  private getFieldErrorClassName(key: string) {
    const errors = this.state.fieldErrors
    if (errors[key]) {
      return 'react-form-text-error'
    }

    return null
  }

  private validateFields(): ValidationErrors {
    const validationResult = {}
    this.validatePhoneNumber(
      validationResult,
      'businessPhoneNumber',
      this.state.businessPhoneNumber
    )
    this.validatePhoneNumber(
      validationResult,
      'homePhoneNumber',
      this.state.homePhoneNumber
    )
    this.validatePhoneNumber(
      validationResult,
      'mobilePhoneNumber',
      this.state.mobilePhoneNumber,
      'Invalid mobile number. Please try again'
    )
    this.validateEmail(validationResult, 'email', this.state.email)

    this.validateMandatory(
      validationResult,
      'country',
      this.state.country,
      'Country'
    )
    this.validateMandatory(
      validationResult,
      'addressLine1',
      this.state.addressLine1,
      'Address'
    )
    this.validateMandatory(validationResult, 'city', this.state.city, 'City')

    return validationResult
  }

  private clearFieldsError(field: string, errors: ValidationErrors) {
    const newErrors: ValidationErrors = { ...errors, [field]: null }
    return newErrors
  }

  private validateFieldsOnChange(target: HTMLInputElement) {
    let errors: ValidationErrors = this.state.fieldErrors
    switch (target.id) {
      case 'businessPhoneNumber':
      case 'homePhoneNumber':
        errors = this.clearFieldsError(target.id, errors)
        this.validatePhoneNumber(errors, target.id, target.value)
        break
      case 'mobilePhoneNumber':
        errors = this.clearFieldsError(target.id, errors)
        this.validatePhoneNumber(
          errors,
          target.id,
          target.value,
          'Invalid mobile number. Please try again'
        )
        break
      default:
        break
    }
    this.setState({
      fieldErrors: errors,
    })
  }
}

const mapDispatchToProps = (dispatch: Dispatch<UserActions>) => ({
  saveContactDetails: bindActionCreators(UserUpdateDetailsAction, dispatch),
  updateSavingStatus: bindActionCreators(
    UserUpdateSavingStatusAction,
    dispatch
  ),
})

export default connect(null, mapDispatchToProps, null)(ContactDetailsForm)
