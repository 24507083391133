import 'airbnb-browser-shims'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { browserHistory } from 'react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorBoundary as BugsnagErrorBoundary } from './common/bugsnag'
import { persistor, store } from './redux/store'
import { AppRouter } from './routing/app.router'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'react-app-polyfill/ie11'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'shared/dist/fonts.css'
import { initDatadog } from './initDatadog'
import {
  AppState,
  Auth0Provider,
  Auth0ProviderOptions,
} from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

initDatadog()

const providerConfig: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirectUri: window.location.origin,
  onRedirectCallback: (appState?: AppState): void => {
    const loc = window.location
    // We specifically leave search out to not drag auth0 login params
    const url = appState?.returnTo || loc.pathname + loc.hash
    browserHistory.replace(url)
  },
}

// sentry.io
const sampleRate = process.env.REACT_APP_SENTRY_SAMPLE_RATE
  ? parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE)
  : 1.0

Sentry.init({
  dsn:
    'https://e7a43fa9551343aea782edfff220fd2a@o510873.ingest.sentry.io/6268268',
  integrations: [new BrowserTracing()],
  tracesSampleRate: sampleRate,
  normalizeDepth: 10,
  environment: process.env.REACT_APP_CONTENTFUL_ENV,
})

const root = createRoot(document.getElementById('root'))
root.render(
  <BugsnagErrorBoundary>
    <Auth0Provider {...providerConfig}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {AppRouter()}
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </BugsnagErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready
    .then((registration) => {
      registration.unregister()
    })
    .catch((error) => console.log('ServiceWorker Warning: ', error))
}
