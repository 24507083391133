import { Account } from '../redux/accounts/accounts.model'
import {
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
  // isKiwiSaverTwoAccount,
} from './accounts-helper'
import { isOverRetirementAge } from './user-helper'

export const canWithdrawFunds = (account: Account, birthDate?: string) => {
  if (!account) {
    return false
  }

  return (
    isInvestmentFundsAccount(account) ||
    (!!birthDate &&
      isOverRetirementAge(birthDate) &&
      isKiwiSaverPlanAccount(account))
    // || isKiwiSaverTwoAccount(account, false)) // TODO: Waiting for DCX-2813 to enable this.
  )
}
