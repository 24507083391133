import React, { useMemo } from 'react'
import './AccountPerformanceSummary.scss'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import Currency from '../currency/Currency'

import AccountPerformanceSummarySection from './AccountPerformanceSummarySection'

interface AccountSectionsProps {
  sections: Section[]
  currentBalance: number
}

export type Detail = {
  detailLabel: string
  detailTotal: number
  detailTooltip?: string
}

export type Section = {
  details: Detail[]
  label: string
  total: number
  color: string
}

export default function AccountPerformanceSummary(props: AccountSectionsProps) {
  const { sections, currentBalance } = props
  // Display investment earnings at top without drop down icon
  const [investmentEarnings, summarySections] = useMemo(() => {
    return [sections[0], sections.slice(1)]
  }, [sections])

  return (
    <div className="account-performance-summary-component">
      <Table>
        <TableBody>
          <TableRow className="investment-earnings">
            <TableCell component="th" className="label">
              {investmentEarnings?.label}
            </TableCell>
            <TableCell>
              {investmentEarnings?.total && (
                <Currency
                  className={
                    investmentEarnings.total >= 0
                      ? 'currency-positive'
                      : 'currency-negative'
                  }
                  value={investmentEarnings.total}
                />
              )}
            </TableCell>
          </TableRow>
          {summarySections.map((section: Section, index: number) => (
            <AccountPerformanceSummarySection
              section={section}
              index={index}
              key={section.label}
            />
          ))}
          <TableRow className="closing-value-row">
            <TableCell component="th" className="label">
              Net balance
            </TableCell>
            <TableCell className="positive-value">
              <Currency value={currentBalance} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
