import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { isEqual } from 'lodash'
import { Notification } from 'shared'
import { StrategyFundAllocation } from './SwitchContentfulModal.types'
import { Account } from '../../redux/accounts/accounts.model'
import Checkbox from '../../common/forms/Checkbox'
import { Button } from '../clickable/button/Button'
import { isFundAvailable } from '../../utils/fund'
import CustomStrategySelector, {
  CustomStrategyProps,
  FundAllocation,
} from './CustomStrategySelector/CustomStrategySelector'
import {
  isApexProductAccount,
  isInvestmentFundsAccount,
  isKiwiSaverPlanAccount,
} from '../../common/accounts-helper'

import './BuildYourOwnStrategy.scss'

export type FundSplit = {
  id: string
  name: string
  ratio: number
}
export type BuildYourOwnSplit = {
  currentSplit: FundSplit[]
  futureSplit: FundSplit[]
}

type Props = {
  className?: string
  strategyFundAllocations: StrategyFundAllocation[]
  account: Account
  onCancel: () => void
  onSubmit: (formData: BuildYourOwnSplit) => void
  isStrategyProduct: boolean
  isKiwiSaver: boolean
} & Omit<
  CustomStrategyProps,
  'onChange' | 'incrementSize' | 'initialFundAllocation'
>

const splitSums100 = (split: FundAllocation[]) =>
  split.reduce((sum, item) => sum + item.ratio, 0) === 100

export const BuildYourOwnStrategy = ({
  className,
  account,
  onCancel,
  onSubmit,
  strategyFundAllocations,
  isStrategyProduct,
  isKiwiSaver,
  ...customStrategySelectorProps
}: Props) => {
  const [isBuildingCurrentBalance, setIsBuildingCurrentBalance] = useState(true)
  const [currentSplit, setCurrentSplit] = useState([])
  const [isBuildingFutureBalance, setIsBuildingFutureBalance] = useState(true)
  const [futureSplit, setFutureSplit] = useState([])

  const initialFundAllocation = useMemo(() => {
    return strategyFundAllocations.map((fundAllocation) => ({
      id: fundAllocation.fund.telCode,
      name: fundAllocation.fund.shortName,
      ratio: fundAllocation.allocationPercentage || 0,
    }))
  }, [strategyFundAllocations])

  const hasTwoCashEnchancedFund = useMemo(() => {
    return strategyFundAllocations.some(({ fund }) => {
      return fund.legacy && fund.name === 'Fisher Funds TWO Cash Enhanced Fund'
    })
  }, [strategyFundAllocations])

  const isKPOrIFAccount =
    (account && isKiwiSaverPlanAccount(account)) ||
    isInvestmentFundsAccount(account)
  const showFutSplitOnly =
    isApexProductAccount(account?.productExternalRef) && isKPOrIFAccount

  const isSameAsExistingAllocation = useMemo(() => {
    return isEqual(initialFundAllocation, futureSplit)
  }, [futureSplit, initialFundAllocation])

  useEffect(() => {
    setFutureSplit(initialFundAllocation)
  }, [initialFundAllocation])

  useEffect(() => {
    if (showFutSplitOnly) {
      setIsBuildingCurrentBalance(!isKPOrIFAccount)
    }
  }, [showFutSplitOnly, isKPOrIFAccount])

  useEffect(() => {
    if (!isBuildingCurrentBalance) {
      setCurrentSplit([])
    }
    if (!isBuildingFutureBalance) {
      setFutureSplit([])
    }
  }, [isBuildingCurrentBalance, isBuildingFutureBalance])

  const validFundAllocations = useMemo(() => {
    return strategyFundAllocations.filter(({ fund }) =>
      isFundAvailable(fund, account?.funds)
    )
  }, [strategyFundAllocations, account])

  const isSubmitValid = useMemo(() => {
    if (!isBuildingCurrentBalance && !isBuildingFutureBalance) {
      return false
    }
    if (isBuildingCurrentBalance && !splitSums100(currentSplit)) {
      return false
    }
    if (isBuildingFutureBalance && !splitSums100(futureSplit)) {
      return false
    }
    if (showFutSplitOnly && isSameAsExistingAllocation) {
      return false
    }
    return true
  }, [
    isBuildingCurrentBalance,
    isBuildingFutureBalance,
    currentSplit,
    futureSplit,
    isSameAsExistingAllocation,
    showFutSplitOnly,
  ])

  const handleSubmit = () => {
    onSubmit({ currentSplit, futureSplit })
  }

  //FFKP & FFIF are limited to 5% increments due to alignment with the PDS. Other products work with 1%.
  const sliderIncrementSize =
    isKiwiSaverPlanAccount(account) || isInvestmentFundsAccount(account) ? 5 : 1

  return (
    <div
      className={cn('build-your-own-strategy', {
        [`${className}`]: !!className,
      })}
    >
      <h6>
        {isStrategyProduct
          ? 'Build your own strategy'
          : 'Build your own fund mix'}
      </h6>
      <p>
        Drag the bars to select the percentage of each fund you would like to
        invest in. The total must add up to 100%.
      </p>
      {showFutSplitOnly ? (
        <CustomStrategySelector
          initialFundAllocation={initialFundAllocation}
          className="mt-lg"
          strategyFundAllocations={validFundAllocations}
          onChange={setFutureSplit}
          lite
          incrementSize={sliderIncrementSize}
          {...customStrategySelectorProps}
        />
      ) : (
        <>
          {hasTwoCashEnchancedFund && (
            <Notification className="mt-md" type="info">
              The Cash Enhanced Fund (our default fund) will be wound up in due
              course, as such any full withdrawals or switches out of the fund
              will mean you are no longer able to invest in the Cash Enhanced
              Fund
            </Notification>
          )}

          {!isBuildingCurrentBalance && !isBuildingFutureBalance && (
            <Notification className="mt-md" type="error">
              Please select current and/or future investments.
            </Notification>
          )}
          <div className="build-your-own-grid strategy-option">
            <div className="flex-row-center">
              <Checkbox
                id="fundext"
                name="fundext"
                checked={isBuildingCurrentBalance}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsBuildingCurrentBalance(e.target.checked)
                }
              />
              <label className="text-large text-bold ml-2xs" htmlFor="fundext">
                Current Balance
              </label>
            </div>
            {isBuildingCurrentBalance && validFundAllocations.length && (
              <CustomStrategySelector
                className="mt-lg"
                strategyFundAllocations={validFundAllocations}
                onChange={setCurrentSplit}
                lite
                initialFundAllocation={initialFundAllocation}
                incrementSize={sliderIncrementSize}
                {...customStrategySelectorProps}
              />
            )}
          </div>
          <div className="build-your-own-grid strategy-option">
            <div className="flex-row-center">
              <Checkbox
                id="fundfut"
                name="fundfut"
                checked={isBuildingFutureBalance}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsBuildingFutureBalance(e.target.checked)
                }
              />
              <label className="text-large text-bold ml-2xs" htmlFor="fundfut">
                Future <span className="text-capitilize">investments</span>
                {!isKiwiSaver && (
                  <>
                    {' '}
                    <span>(regular or lump sum)</span>
                  </>
                )}
              </label>
            </div>
            {isBuildingFutureBalance && validFundAllocations.length && (
              <CustomStrategySelector
                className="mt-lg"
                strategyFundAllocations={validFundAllocations}
                onChange={setFutureSplit}
                lite
                incrementSize={sliderIncrementSize}
                initialFundAllocation={initialFundAllocation}
                {...customStrategySelectorProps}
              />
            )}
          </div>
        </>
      )}

      <div className="flex-row mt-md">
        <Button onClick={onCancel} variant="link">
          Cancel
        </Button>
        <Button
          className="ml-auto"
          onClick={handleSubmit}
          disabled={!isSubmitValid}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
