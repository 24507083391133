import cn from 'classnames'
import {
  getEquivalentFundCode,
  getInvestmentOptionByCode,
  isCustomInvestment,
} from '../../utils/investmentOption'
import { APEX_FUNDS_CODES } from '../../utils/fund'
import { getIPQInvestmentOptions } from '../ipq/utils'
import { Product } from '../../common/product-helper'
import { useEffect } from 'react'
import { InvestmentOptionType, OtherInvestmentOption } from './InvestmentOption'
import InvestmentOption from './InvestmentOption'
import { isGlidePath } from '../../utils/strategy'
import './CompareInvestmentOptions.scss'
import { Button, IconArrowLeft } from 'shared'

export type Props = {
  product: Product
  currentInvestmentOption: InvestmentOptionType
  newInvestmentOption: InvestmentOptionType
  onCancel: (investmentOption?: InvestmentOptionType) => void
  onSelect: (investmentOption: InvestmentOptionType) => void
  onBack?: () => void
  setDisplayedInvestmentOption?: (result: InvestmentOptionType) => void
  onOptionsChange: (needsLargeModal: boolean) => void
}

const CompareInvestmentOptions = ({
  product,
  currentInvestmentOption,
  newInvestmentOption,
  onOptionsChange,
  setDisplayedInvestmentOption,
  onCancel,
  onBack,
  onSelect,
}: Props) => {
  const equivalentFundCode = getEquivalentFundCode(newInvestmentOption.telCode)

  const showingOptionsTelCodes = [
    currentInvestmentOption?.telCode,
    newInvestmentOption?.telCode,
    equivalentFundCode,
  ].filter(Boolean)

  const otherInvestmentOptions = (getIPQInvestmentOptions(
    product
  ) as OtherInvestmentOption[]).filter(
    (option) => !showingOptionsTelCodes.includes(option.telCode)
  )

  const areSameStrategyCode =
    currentInvestmentOption.telCode === newInvestmentOption.telCode &&
    isGlidePath(currentInvestmentOption.strategy) ===
      isGlidePath(newInvestmentOption.strategy)

  const isComparedOptionSameAsCurrent =
    !!currentInvestmentOption &&
    !!newInvestmentOption &&
    !isCustomInvestment(currentInvestmentOption) &&
    areSameStrategyCode

  const shouldDisplayDefaultOption =
    equivalentFundCode &&
    currentInvestmentOption?.telCode !== equivalentFundCode

  const showThreeOptions =
    shouldDisplayDefaultOption &&
    !isComparedOptionSameAsCurrent &&
    currentInvestmentOption?.telCode !== APEX_FUNDS_CODES.FFKP_DEFAULT

  useEffect(() => {
    onOptionsChange(!!currentInvestmentOption && showThreeOptions)
  }, [showThreeOptions, onOptionsChange, currentInvestmentOption])

  if (
    (isComparedOptionSameAsCurrent && !shouldDisplayDefaultOption) ||
    !currentInvestmentOption
  ) {
    return (
      <InvestmentOption
        className="side-by-side"
        investmentOption={currentInvestmentOption ?? newInvestmentOption}
        isCurrent={!!currentInvestmentOption}
        product={product}
        onSelect={onSelect}
        setDisplayedInvestmentOption={setDisplayedInvestmentOption}
        otherInvestmentOptions={otherInvestmentOptions}
      />
    )
  }
  return (
    <div className="compare-investment-options">
      <div
        className={cn('investment-options', {
          'has-other-options-select': !!setDisplayedInvestmentOption,
        })}
      >
        <InvestmentOption
          className="side-by-side"
          product={product}
          isCurrent
          investmentOption={currentInvestmentOption}
          onSelect={onCancel}
        />
        {!!shouldDisplayDefaultOption && (
          <InvestmentOption
            className="side-by-side"
            product={product}
            investmentOption={getInvestmentOptionByCode(
              product,
              equivalentFundCode
            )}
            setDisplayedInvestmentOption={setDisplayedInvestmentOption}
            otherInvestmentOptions={otherInvestmentOptions}
            onSelect={onSelect}
          />
        )}
        {!!newInvestmentOption && !isComparedOptionSameAsCurrent && (
          <InvestmentOption
            className="side-by-side"
            product={product}
            investmentOption={newInvestmentOption}
            onSelect={onSelect}
            setDisplayedInvestmentOption={setDisplayedInvestmentOption}
            otherInvestmentOptions={otherInvestmentOptions}
          />
        )}
      </div>
      {onBack && (
        <Button
          className="mt-md mr-md"
          onClick={onBack}
          variant="link"
          iconLeft={IconArrowLeft}
        >
          I want to make a change
        </Button>
      )}
    </div>
  )
}

export default CompareInvestmentOptions
