import React from 'react'
import './BankAccountInputSection.scss'
// @ts-ignore
import { getInputSelection } from '../../common/shared-utils/get-input-selection'
import { useMedia } from '../../hooks/use-media'

export type BankAccountInputSectionProps = {
  value: string
  label: string
  tabIndex: number
  maxLength: number
  inputRef?: React.RefObject<HTMLInputElement>
  prevRef: React.RefObject<HTMLInputElement>
  nextRef?: React.RefObject<HTMLInputElement> | null
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  onChange: (value: string) => void
  onPaste: () => void
  disabled?: boolean
}

const setCursor = (
  ref: React.RefObject<HTMLInputElement>,
  position?: number
) => {
  ref.current?.setSelectionRange(position || 0, position || 0)
}

export const BankAccountInputSection = ({
  value,
  label,
  tabIndex,
  maxLength,
  inputRef,
  prevRef,
  nextRef,
  onClick,
  onChange,
  onPaste,
  disabled,
}: BankAccountInputSectionProps) => {
  const { isMobile } = useMedia()
  const size = isMobile ? 10 : 14

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const cursor = getInputSelection(e.currentTarget).end || 0
    if (e.key === 'Backspace') {
      if (prevRef?.current && cursor === 0) {
        prevRef?.current?.focus()
        setCursor(prevRef, prevRef.current?.value.length)
      }
    } else if (
      !Number.isNaN(Number(e.key)) &&
      cursor >= maxLength - 1 &&
      nextRef?.current
    ) {
      nextRef.current.focus()
      setCursor(nextRef, 1)
    }
    if (e.key === 'ArrowLeft' && cursor === 0) {
      e.preventDefault()
      if (prevRef) {
        prevRef.current?.focus()
        setCursor(prevRef, prevRef.current?.value.length)
      }
    }

    if (e.key === 'ArrowRight' && cursor >= maxLength) {
      e.preventDefault()
      if (nextRef) {
        nextRef.current?.focus()
        setCursor(nextRef, 0)
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caret = getInputSelection(e.target).start || 0

    if (caret < maxLength) {
      const element = e.target
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret)
      })
    }
    onChange(e.currentTarget.value)
  }
  return (
    <div
      className="bank-account-input-section__container"
      style={{ width: `${size * maxLength + 1}px` }}
    >
      <input
        className="bank-account-input-section__input"
        type="text"
        value={value}
        tabIndex={tabIndex}
        ref={inputRef}
        pattern="\d*"
        inputMode="numeric"
        onChange={handleChange}
        onPaste={onPaste}
        onClick={onClick}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      <p className="bank-account-input-section__label">{label}</p>
    </div>
  )
}
