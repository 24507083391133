import bankData from './nz-bank-branchs.json'

const isValidBankAndBranch = (bankId: string, branch: string) => {
  if (!bankData.map((bank) => bank.id).includes(parseInt(bankId, 10))) {
    return false
  }

  const bank = bankData.find((b) => b.id === parseInt(bankId, 10))

  return (
    bank &&
    bank.branches
      .map((brch) => parseInt(branch, 10) >= brch.from && +branch <= brch.to)
      .some((r) => r)
  )
}

const getWeightFactor = (bankId: string, accountNumber: string) => {
  switch (parseInt(bankId, 10)) {
    case 8:
      return '000000076543210000'
    case 9:
      return '000000000054320001'
    case 25:
    case 33:
      return '000000017317310000'
    case 26:
    case 28:
    case 29:
      return '000000013713710371'
    case 31:
      return '000000000000000000'
    default:
      if (parseInt(accountNumber, 10) < 990000) {
        return '00637900A584210000'
      }
      return '00000000A584210000'
  }
}

const calcSum = (bankId: string, result: number[]) => {
  switch (parseInt(bankId, 10)) {
    case 9:
    case 26:
    case 28:
    case 29:
      result
        .map((r) => {
          if (r < 10) {
            return r
          }
          return r
            .toString()
            .split('')
            .reduce((acc, cur) => parseInt(cur, 10) + acc, 0)
        })
        .map((r) => {
          if (r < 10) {
            return r
          }
          return r
            .toString()
            .split('')
            .reduce((acc, cur) => parseInt(cur, 10) + acc, 0)
        })
        .reduce((acc, curr) => acc + curr)
      return 0
    default:
      return result.reduce((acc, curr) => acc + curr, 0)
  }
}

const getModulo = (bankId: string) => {
  switch (parseInt(bankId, 10)) {
    case 25:
    case 33:
    case 26:
    case 28:
    case 29:
      return 10
    case 31:
      return 1
    default:
      return 11
  }
}

export const isValidNZBankNumber = (
  bk: string,
  brch: string,
  acct: string,
  suf: string
) => {
  const bank = bk.padStart(2, '0')
  const branch = brch.padStart(4, '0')
  const account = acct.padStart(8, '0')
  const suffix = suf.padStart(4, '0')

  if (parseInt(account, 10) === 0) {
    return false
  }

  if (!isValidBankAndBranch(bank, branch)) {
    return false
  }

  const checkArray = (bank + branch + account + suffix)
    .split('')
    .map((d) => parseInt(d, 10))

  if (checkArray.length !== 18) {
    return false
  }

  const weightFactor = getWeightFactor(bank, account)
    .split('')
    .map((v) => parseInt(v, 16))

  const result = weightFactor.map((v, i) => v * +checkArray[i])

  const sum = calcSum(bank, result)

  const checkNumber = getModulo(bank)
  return sum % checkNumber === 0
}
