import Modal from '../modal/Modal'
import { Button } from 'shared'

export type TermsModalProps = {
  onClose: () => void
}

const TermsModalFFTWO = (props: TermsModalProps) => (
  <Modal
    title="Terms and conditions"
    open
    className="review-your-strategy-change switch-sub-modal"
    onClose={props.onClose}
  >
    <div>
      <p>
        I hereby request Fisher Funds to direct future contributions to and/or
        change my current balance in accordance with my instructions in this
        form and declare that:
      </p>
      <ol>
        <li>I am authorised to make investment decisions for this account.</li>
        <li>
          I understand that it may take up to three business days for you to
          complete changing my strategy.
        </li>
        <li>
          I understand that changing my current balance to a different strategy
          requires you to "sell" investments from my current strategy and "buy"
          investments in my chosen strategy and that any applicable fees or
          taxes may be deducted from my account.
        </li>
      </ol>
      <div className="flex-row-reverse">
        <Button onClick={props.onClose}>Okay, got it</Button>
      </div>
    </div>
  </Modal>
)

export default TermsModalFFTWO
