import { Account } from '../redux/accounts/accounts.model'
import { hasWriteAuthorisation } from './accounts-helper'
import {
  INVESTMENT_FUNDS,
  KIWISAVER_ONE,
  KIWISAVER_PLAN,
  KIWISAVER_TWO_TEL,
  KIWISAVER_TWO_APEX,
  MANAGED_FUNDS,
} from './product-variables'

export const isApexSwitchEnabled =
  process.env.REACT_APP_FEATURE_TOGGLE_APEX_SWITCH !== 'false'

// The following products have a switch definition in contentful
const switchContentfulDefs = [
  KIWISAVER_ONE,
  KIWISAVER_TWO_TEL, // TODO: Once FFTWO has migrated to Apex, delete references to TEL FFTWO.
  KIWISAVER_TWO_APEX,
  MANAGED_FUNDS,
  ...(isApexSwitchEnabled ? [KIWISAVER_PLAN, INVESTMENT_FUNDS] : []),
]

export const hasContentfulSwitchDef = (productExternalRef: string): boolean => {
  return switchContentfulDefs.includes(productExternalRef)
}

export const canSwitchModal = (account: Account) => {
  if (!account || !!account.isDepositMode) {
    return false
  }
  return (
    hasWriteAuthorisation(account) &&
    switchContentfulDefs.includes(account.productExternalRef)
  )
}
