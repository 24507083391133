import AtomicSDK, {
  AACStreamContainer,
  SDKConfiguration,
} from '@atomic.io/action-cards-web-sdk'
import React, { useCallback, useRef } from 'react'
import { formatCurrency } from '../common/currency-helper'
import axios from 'axios'
import debounce from 'lodash/debounce'
import { Account } from '../redux/accounts/accounts.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL
const ENABLE_ATOMIC = process.env.REACT_APP_FEATURE_TOGGLE_ATOMIC === 'true'
const ATOMIC_STREAM_CONTAINER_ID =
  process.env.REACT_APP_ATOMIC_STREAM_CONTAINER_ID

const getAtomicConfig = (totalBalance: number, accountErrors: Account[]) =>
  ({
    streamContainerId: ATOMIC_STREAM_CONTAINER_ID,
    enabledUiElements: {
      cardListHeader: false,
    },
    onRuntimeVariablesRequested: (cards, callback) => {
      cards.forEach(function (card) {
        card.runtimeVariables.set(
          'TotalBalance',
          !accountErrors?.length
            ? formatCurrency(totalBalance, '$0,0.00')
            : '--.--'
        )
      })
      callback(cards)
    },
  } as SDKConfiguration)

export function useAtomic(
  totalBalance: number,
  accountErrors: Account[],
  fscClientID: string,
  authToken: string
) {
  const getJwt = debounce(
    async () => {
      try {
        const response = await axios.get(
          `${ffmAppServerUrl}/api/v1/client/${fscClientID}/atomic/token`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        )
        return response.data.jwt
      } catch (e) {
        throw Error(`Get Atomic JWT Failure: ${e}`)
      }
    },
    1000,
    { leading: true, trailing: false }
  )

  ENABLE_ATOMIC && AtomicSDK.setSessionDelegate(getJwt)

  const streamContainerRef = useRef<AACStreamContainer>()
  const initAtomicEmbed = useCallback(
    (el: HTMLDivElement) => {
      if (streamContainerRef.current) {
        streamContainerRef.current.stop()
      }
      if (el) {
        const config = getAtomicConfig(totalBalance, accountErrors)
        streamContainerRef.current = AtomicSDK.embed(el, config)
      }
    },
    [totalBalance, accountErrors]
  )

  return [<div ref={initAtomicEmbed} className="atomic-embed-wrapper" />]
}
