import { useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import Alert from 'react-s-alert'
import debounce from 'lodash/debounce'
import numeral from 'numeral'
import {
  AmountSelectInput,
  SelectOption,
  OptionButtons,
  IconPhone,
  IconArrowLeft,
} from 'shared'
import {
  ContributionFrequency,
  EmploymentStatus,
  RetirementProjectorOutput,
  RetirementProjectorResponse,
  RetirementProjectorState,
} from './RetirementProjector.types'
import { putRetirementProjection } from '../../api/advice/advice-api'
import { RetirementProjectorGraph } from './RetirementProjectorGraph'
import Currency from '../../components/currency/Currency'
import { RetirementProjectorFundOption } from './RetirementProjectorFundOption'
import Link from '../../components/clickable/link/Link'
import { Button } from '../../components/clickable/button/Button'
import { history } from '../../routing/history'
import { ReactComponent as IconHelp } from '../../assets/images/icons/icon_help.svg'
import { ProgressBarContextProvider } from '../../hooks/useProgressBar'
import IPQ from '../../components/ipq/IPQ'
import { Account } from '../../redux/accounts/accounts.model'
import Toggle from './Toggle'
import './RetirementProjectorResults.scss'
import {
  isCashPlusLegacy,
  isKiwiSaverTwoAccount,
  isMixMandate,
} from '../../common/accounts-helper'
import SwitchModal from '../../components/switch-contentful-modal/SwitchModal'
import {
  IInvestmentOption,
  getInvestmentOptionByCode,
  isCustomProfile,
} from '../../utils/investmentOption'
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner'
import { Product, productHasStrategies } from '../../common/product-helper'
import InvestmentOptionInfoModal from '../../components/investment-option-info/InvestmentOptionInfoModal'
import { AccountDetail } from '../../redux/account-details/account-details.model'

const WEEKS_IN_A_YEAR = numeral(365.25).divide(7).value()

export interface RetirementProjectorResultsProps {
  investmentOptions: IInvestmentOption[]
  accountDetails: AccountDetail
  currentAccount: Account
  state: RetirementProjectorState
  setState: (state: RetirementProjectorState) => void
  handleBackToAboutYou: () => void
  authToken: string
  contentfulProduct: Product
}

export const RetirementProjectorResults = ({
  investmentOptions,
  currentAccount,
  state,
  accountDetails,
  setState,
  handleBackToAboutYou,
  authToken,
  contentfulProduct,
}: RetirementProjectorResultsProps) => {
  const [projection, setProjection] = useState<RetirementProjectorOutput>()
  const [isLoading, setIsLoading] = useState(false)
  const [retirementIncomeFrequency, setRetirementIncomeFrequency] = useState<
    ContributionFrequency
  >(ContributionFrequency.WEEKLY)
  const [isSubmittingAction, setIsSubmittingAction] = useState(false)

  const isFFTWOCustomStrategy =
    isCustomProfile(accountDetails.profile?.profileCode) &&
    isKiwiSaverTwoAccount(currentAccount, false)
  const isMixMandateAccount = isMixMandate(currentAccount)
  const isCashPlusLegacyAccount = isCashPlusLegacy(currentAccount)

  const strategyOrProductVariant = productHasStrategies(contentfulProduct?.slug)
    ? 'strategy'
    : 'fund'

  const optionCode =
    accountDetails.profile?.profileCode ?? state.kiwiSaverFunds[0]?.assetCode
  const initialAccountOption =
    isMixMandateAccount || isCashPlusLegacyAccount
      ? undefined
      : getInvestmentOptionByCode(
          contentfulProduct,
          isKiwiSaverTwoAccount(currentAccount, false)
            ? optionCode
            : state.kiwiSaverFunds[0]?.assetCode
        )

  const getCurrentInvestmentOptionName = () => {
    if (isFFTWOCustomStrategy) {
      return 'Custom Strategy'
    }
    if (isMixMandateAccount) {
      return 'Mixed Funds'
    }
    if (isCashPlusLegacyAccount) {
      return 'CashPlus Fund'
    }
    if (initialAccountOption) {
      return initialAccountOption.shortName
    }
    return '-'
  }

  const [IPQButtonOpen, setIPQButtonOpen] = useState(false)
  const [showSwitchModal, setShowSwitchModal] = useState(false)
  const [investmentOptionInfoFund, setInvestmentOptionInfoFund] = useState<
    IInvestmentOption
  >(null)

  useEffect(() => {
    const mainContent = document.querySelector('.main-content-padding')

    if (mainContent) {
      window.scrollTo(0, 0)
      mainContent.scrollTo(0, 0)
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedProjectionRequest = useCallback(
    debounce((projectionState: RetirementProjectorState) => {
      setIsLoading(true)
      putRetirementProjection(
        'Incomplete',
        authToken,
        projectionState.dalId,
        currentAccount.accountNumber,
        null,
        projectionState
      )
        .then((response: RetirementProjectorResponse) => {
          setProjection(response.projectorOutputs)
        })
        .catch(() => {
          Alert.error('<p> Failed to get retirement projection</p>', {
            timeout: 10000,
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 300),
    []
  )

  useEffect(
    () => {
      debouncedProjectionRequest(state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  const investmentOptionInfoModal = useMemo(() => {
    return investmentOptionInfoFund
  }, [investmentOptionInfoFund])

  useEffect(() => {
    debouncedProjectionRequest(state)
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOutcome = (type: 'Remain' | 'Switch', option: string) => {
    setIsSubmittingAction(true)
    putRetirementProjection(
      'Complete',
      authToken,
      state.dalId,
      currentAccount.accountNumber,
      {
        type,
        option,
      }
    ).finally(() => {
      setIsSubmittingAction(false)
    })
  }

  const projectionGraph = useMemo(() => {
    const graphReady = !!projection && !isLoading
    return (
      <div
        className={cn('retirement-projector__results__graph', {
          'is-loading': !graphReady,
        })}
      >
        {!graphReady && (
          <div className="retirement-projector__results__loading-cover">
            <LoadingSpinner center />
          </div>
        )}
        {!!projection && <RetirementProjectorGraph data={projection.years} />}
      </div>
    )
  }, [projection, isLoading])

  const retirementIncome = useMemo(() => {
    if (!projection) {
      return 0
    }
    const { incomeInRetirementAdjusted, superWeeklyIncome = 0 } = projection

    const totalAnnualIncome = numeral(superWeeklyIncome)
      .multiply(WEEKS_IN_A_YEAR)
      .add(incomeInRetirementAdjusted)
      .value()

    switch (retirementIncomeFrequency) {
      case ContributionFrequency.WEEKLY:
        return numeral(totalAnnualIncome).divide(WEEKS_IN_A_YEAR).value()
      case ContributionFrequency.MONTHLY:
        return numeral(totalAnnualIncome).divide(12).value()
      case ContributionFrequency.FORTNIGHTLY:
        return numeral(totalAnnualIncome)
          .divide(WEEKS_IN_A_YEAR)
          .multiply(2)
          .value()
      default:
        return totalAnnualIncome
    }
  }, [projection, retirementIncomeFrequency])

  const SwitchModalMemo = useMemo(
    () => {
      return (
        <SwitchModal
          account={currentAccount}
          onClose={() => setShowSwitchModal(false)}
          switchFromOption={initialAccountOption}
          switchToOption={
            !!state.aboutYou.option &&
            getInvestmentOptionByCode(
              contentfulProduct,
              state.aboutYou.option.telCode
            )
          }
        />
      )
    },
    [currentAccount, state.aboutYou.option] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className="retirement-projector__results">
      <h4>Your retirement projection</h4>
      <p>
        <span className="text-large">
          Your estimated KiwiSaver balance at age 65 is
        </span>
        <span className="retirement-projector__results__large-currency ml-xs">
          {projection ? (
            <>
              <Currency
                value={projection?.startBalanceInRetirementAdjusted}
                format="$0,0"
              />
              <sup className="retirement-projector__results__superscript ml-2xs">
                1
              </sup>
            </>
          ) : (
            '$ - '
          )}
          <span className="text-large ml-2xs">.</span>
        </span>
      </p>

      <p className="retirement-projector__results__income">
        <span className="text-large">This will give you an income of </span>

        <span className="retirement-projector__results__large-currency">
          {retirementIncome ? (
            <Currency value={retirementIncome} format="$0,0" />
          ) : (
            '$ - '
          )}
        </span>
        <span className="pb-2xs">
          <select // eslint-disable-line jsx-a11y/no-onchange
            className="retirement-projector__results__income__select text-large"
            value={retirementIncomeFrequency}
            onChange={(e) =>
              setRetirementIncomeFrequency(parseInt(e.target.value))
            }
          >
            {[
              { label: 'per week', value: ContributionFrequency.WEEKLY },
              {
                label: 'per fortnight',
                value: ContributionFrequency.FORTNIGHTLY,
              },
              { label: 'per month', value: ContributionFrequency.MONTHLY },
              { label: 'per year', value: ContributionFrequency.YEARLY },
            ].map((option: SelectOption) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </span>
        <span className="text-large">up to age 90.</span>
      </p>

      <p className="flex-row-center text-large mt-sm">
        Include NZ Super?
        <Toggle
          className="ml-sm"
          checked={state.includeSuper}
          onChange={(e: any) =>
            setState({
              ...state,
              includeSuper: e.target.checked,
            })
          }
        />
      </p>

      {projectionGraph}

      <div className="retirement-projector__results__change">
        <h6>See the difference some changes could make</h6>
        <div className="retirement-projector__results__change__contributions">
          <p className="text-regular text-bold">Change your contributions</p>
          <div className="retirement-projector__grid">
            {state.aboutYou.employmentStatus === EmploymentStatus.EMPLOYED && (
              <>
                <div>
                  <div className="retirement-projector__results__change__contributions__label">
                    Your contributions{' '}
                  </div>
                  <OptionButtons
                    setSelected={(value: number) =>
                      setState({
                        ...state,
                        aboutYou: {
                          ...state.aboutYou,
                          contributionRate: value,
                        },
                      })
                    }
                    value={state.aboutYou.contributionRate}
                    options={[
                      { label: '3%', value: 3 },
                      { label: '4%', value: 4 },
                      { label: '6%', value: 6 },
                      { label: '8%', value: 8 },
                      { label: '10%', value: 10 },
                    ]}
                  />
                </div>
                <div>
                  <div className="retirement-projector__results__change__contributions__label">
                    Employer contributions
                  </div>
                  <OptionButtons
                    setSelected={(value: number) =>
                      setState({
                        ...state,
                        aboutYou: {
                          ...state.aboutYou,
                          employerContributionRate: value,
                        },
                      })
                    }
                    value={state.aboutYou.employerContributionRate}
                    options={[
                      { label: '3%', value: 3 },
                      { label: '4%', value: 4 },
                      { label: '6%', value: 6 },
                      { label: '8%', value: 8 },
                    ]}
                  />
                </div>
              </>
            )}
            <div>
              <div className="retirement-projector__results__change__contributions__label">
                Voluntary contributions
              </div>
              <AmountSelectInput
                amountValue={state.aboutYou.voluntaryContribution}
                onAmountChange={(e) => {
                  const { value } = e.target
                  const newValue = value ? parseFloat(value) : undefined

                  setState({
                    ...state,
                    aboutYou: {
                      ...state.aboutYou,
                      voluntaryContribution: newValue,
                    },
                  })
                }}
                error={state.aboutYou.voluntaryContribution === undefined}
                selectValue={state.aboutYou.voluntaryContributionFrequency}
                options={[
                  { label: 'Weekly', value: ContributionFrequency.WEEKLY },
                  {
                    label: 'Fortnightly',
                    value: ContributionFrequency.FORTNIGHTLY,
                  },
                  { label: 'Monthly', value: ContributionFrequency.MONTHLY },
                  { label: 'Yearly', value: ContributionFrequency.YEARLY },
                ]}
                onSelectChange={(value) => {
                  setState({
                    ...state,
                    aboutYou: {
                      ...state.aboutYou,
                      voluntaryContributionFrequency: value as ContributionFrequency,
                    },
                  })
                }}
              />
            </div>
          </div>
        </div>
        <hr />
        <div>
          <p className="text-regular text-bold">
            Change your investment {strategyOrProductVariant}
          </p>
          <div className="retirement-projector__grid">
            {(investmentOptions || []).map((option) => (
              <RetirementProjectorFundOption
                key={option.telCode}
                option={option}
                isCurrent={initialAccountOption?.telCode === option.telCode}
                onMoreInfoClick={setInvestmentOptionInfoFund}
                onSelect={() => {
                  setState({
                    ...state,
                    aboutYou: {
                      ...state.aboutYou,
                      option,
                    },
                  })
                }}
                selected={state.aboutYou.option?.telCode === option.telCode}
              />
            ))}
          </div>
          <Button
            variant="link"
            className="mt-md text-underline"
            onClick={() => {
              setIPQButtonOpen(true)
            }}
          >
            Find out which {strategyOrProductVariant} suits you
          </Button>
        </div>
        <hr />
        <div className="retirement-projector__results__actions">
          <div className="retirement-projector__results__switch-actions">
            {initialAccountOption?.telCode !==
              state.aboutYou.option?.telCode && (
              <Button
                className="mt-sm"
                variant="filled"
                color="primary"
                disabled={isSubmittingAction}
                onClick={() => {
                  setShowSwitchModal(true)
                  handleOutcome('Switch', state.aboutYou.option.shortName)
                }}
              >
                Switch to{' '}
                {state.aboutYou.option ? state.aboutYou.option.shortName : '-'}
              </Button>
            )}
            <Button
              className="mt-sm"
              variant="outlined"
              color="primary"
              disabled={isSubmittingAction}
              onClick={() => {
                handleOutcome('Remain', getCurrentInvestmentOptionName())
                history.push('/accounts')
              }}
            >
              Remain in {getCurrentInvestmentOptionName()}
            </Button>
          </div>
          <Button
            className="mt-sm"
            variant="link"
            color="primary"
            iconLeft={IconArrowLeft}
            onClick={() => handleBackToAboutYou()}
          >
            Make a change
          </Button>
        </div>
      </div>
      <p className="my-md">
        <Link
          to={`${process.env.REACT_APP_WEBSITE_URL}/request-a-call`}
          target="_blank"
          iconLeft={() => <IconPhone theme="dusk" />}
          className="mr-lg my-sm"
        >
          Request a call
        </Link>
        <Link
          to={`${process.env.REACT_APP_WEBSITE_URL}/retirement-projector-assumptions`}
          target="_blank"
          iconLeft={() => <IconHelp />}
          className="my-sm"
        >
          How this retirement projector works
        </Link>
      </p>

      <hr />

      <div className="retirement-projector__results__footnote mb-xl">
        1. This retirement projection is in today's dollars.
      </div>
      {IPQButtonOpen && (
        <ProgressBarContextProvider>
          <IPQ
            onClose={() => setIPQButtonOpen(false)}
            onResult={(investmentOption: IInvestmentOption) => {
              setState({
                ...state,
                aboutYou: {
                  ...state.aboutYou,
                  option: investmentOption,
                },
              })
              setIPQButtonOpen(false)
            }}
            account={currentAccount}
          />
        </ProgressBarContextProvider>
      )}
      {showSwitchModal && SwitchModalMemo}
      {investmentOptionInfoFund && (
        <InvestmentOptionInfoModal
          onClose={() => setInvestmentOptionInfoFund(null)}
          product={contentfulProduct}
          investmentOption={investmentOptionInfoModal}
        />
      )}
    </div>
  )
}
