import { Button } from '../clickable/button/Button'
import Link from '../clickable/link/Link'
import { Notification } from 'shared'
import CustomIcon from '../../common/CustomIcon'

export interface WithdrawModalProps {
  onClose: () => void
}

const FFKPWithdrawFundsPDFContent = ({ onClose }: WithdrawModalProps) => (
  <>
    <Notification type="info" className="mb-lg">
      Online withdrawals are currently unavailable, but will be available in the
      coming months.
    </Notification>

    <h6>For first time withdrawals</h6>
    <p className="my-xs">
      If this is your first retirement withdrawal, download and complete our
      first withdrawal form below and email it to{' '}
      <a href="mailto:enquiries@fisherfunds.co.nz">
        enquiries@fisherfunds.co.nz
      </a>
      .
    </p>
    <Link
      className="mb-md"
      iconLeft={() => <CustomIcon icon="download" />}
      target="_blank"
      to="https://assets.fisherfunds.co.nz/kiwisaver-plan-retirement-withdrawal-form"
    >
      Download first withdrawal form
    </Link>

    <h6>For subsequent withdrawals</h6>
    <p className="my-xs">
      If you've made a previous withdrawal and provided a statutory declaration,
      download and complete our subsequent withdrawal form below and email it to{' '}
      <a href="mailto:enquiries@fisherfunds.co.nz">
        enquiries@fisherfunds.co.nz
      </a>
      .
    </p>
    <Link
      className="mb-lg"
      iconLeft={() => <CustomIcon icon="download" />}
      target="_blank"
      to="https://assets.fisherfunds.co.nz/kiwisaver-plan-subsequent-retirement-withdrawal"
    >
      Download subsequent withdrawal form
    </Link>

    <div>
      <Button onClick={onClose} variant="link">
        Cancel
      </Button>
    </div>
  </>
)

export default FFKPWithdrawFundsPDFContent
