import getAccountsApi from '../../api/accounts'
import { Product } from '../../common/product-helper'
import { ProductShortCodes } from '../../common/product-variables'
import { Account } from '../../redux/accounts/accounts.model'
import { Allocation, isFundAvailable } from '../../utils/fund'
import { StrategyFundAllocation } from './SwitchContentfulModal.types'

type SwitchRequest = {
  switchType: string
  switchStrategy?: string // We don't send this when switchType === GlidePath
  product: string
  currentSplit?: {
    funds: {
      fund: string
      fundCode: string
      percent: number
    }[]
  }
  futureSplit?: {
    profile: string
    assets: {
      name: string
      assetCode: string
      percentSplit: number
    }[]
  }
}

export type SwitchFormData = SwitchFormDataCurrent | SwitchFormDataCustom
export type SwitchFormDataCurrent = {
  strategytype: 'Current' | 'GlidePath'
  strategy: string // salesforceCode
}

export type SwitchRequestApex = {
  switchType: string
  switchStrategy?: string
  product: string
  futureSplit: {
    assets: { percentSplit: number; assetCode: string }[]
  }
}

export type SwitchFormDataCustom = {
  strategytype: 'Custom'
  fundsext?: number[]
  fundsfut?: number[]
}

const defaultFormValues = {
  strategytype: '',
  strategy: '',
  fundsext: [] as number[],
  fundsfut: [] as number[],
}

const SWITCH_TYPE_MAP = {
  Current: 'Strategy',
  Custom: 'Build',
}

type SwitchDataApex = {
  switchType: keyof typeof SWITCH_TYPE_MAP
  switchStrategy?: string
  product: string
  fundAllocation: StrategyFundAllocation[]
}

// TEL API expects to receive all available fund allocations in order. Even if the allocation is 0.
export const mapUserAllocationToSwitchData = (
  strategyAllocations: StrategyFundAllocation[],
  userAllocations: Allocation[]
) => {
  if (!userAllocations?.length) {
    return []
  }
  return strategyAllocations.map(({ fund: { name } }) => {
    const userAllocation = userAllocations.find((a) => a.fund.name === name)
    return userAllocation?.allocationPercentage || 0
  })
}

const formatSwitchType = (switchType: keyof typeof SWITCH_TYPE_MAP) =>
  SWITCH_TYPE_MAP[switchType] || switchType

/**
 * This endpoint is to save the user's decision to remain in his current fund after he compared different options.
 */
type ProductShortCodesValues = typeof ProductShortCodes[keyof typeof ProductShortCodes]
export const createApiRemainDataApex = (
  apiProductCode: ProductShortCodesValues
) => ({
  switchType: 'Remain',
  product: apiProductCode,
})

export const createApiDataForKPAndIF = (switchFormData: SwitchDataApex) => {
  const apiData: SwitchRequestApex = {
    switchType: formatSwitchType(switchFormData.switchType),
    product: switchFormData.product,
    futureSplit: {
      assets: switchFormData.fundAllocation
        .filter((fund) => fund.allocationPercentage !== 0)
        .map((f) => ({
          percentSplit: f.allocationPercentage,
          assetCode: f.fund.telCode,
        })),
    },
  }
  if (apiData.switchType === 'Strategy') {
    apiData.switchStrategy = switchFormData.fundAllocation[0].fund.telCode
  }
  return apiData
}

/**
 * Create API request data for all products excluding FFKP and FFIF
 */
export const createApiData = (
  formData: SwitchFormData,
  contentfulProduct: Product,
  account: Account
): SwitchRequest => {
  const data = { ...defaultFormValues, ...formData } // We do this so lint doesn't complain about non-existing fields that we don't use.
  const apiData: SwitchRequest = {
    switchType: '',
    product: contentfulProduct?.salesforceCode,
  }

  // Map switch strategy
  apiData.switchType =
    data.strategytype === 'Current'
      ? 'Strategy'
      : data.strategytype === 'Custom'
      ? 'Build'
      : data.strategytype

  if (data.strategytype !== 'GlidePath') {
    apiData.switchStrategy =
      apiData.switchType === 'Build' ? 'Custom' : data.strategy
  }

  const { strategies } = contentfulProduct || {}
  const strategy = strategies?.find(
    (x: any) =>
      x.salesforceCode ===
      (apiData.switchType === 'Build' ? 'Custom' : data.strategy)
  )
  const strategyFundAllocations = (
    strategy?.strategyFundAllocations || []
  ).filter((allocation) => isFundAvailable(allocation.fund, account?.funds))

  apiData.currentSplit = {
    funds:
      apiData.switchType === 'Build' && !data.fundsext.length
        ? []
        : strategyFundAllocations.map(({ fund, allocationPercentage }, k) => {
            let percent = allocationPercentage
            if (apiData.switchType === 'Build' && data.fundsext.length) {
              percent = data.fundsext[k]
            }
            return {
              fund: fund.name,
              fundCode: fund.telCode,
              percent: percent || 0,
            }
          }),
  }
  apiData.futureSplit = {
    profile: strategy?.telCode || '',
    assets:
      apiData.switchType === 'GlidePath' ||
      (apiData.switchType === 'Build' && !data.fundsfut.length)
        ? []
        : strategyFundAllocations.map(({ fund, allocationPercentage }, k) => {
            let percent = allocationPercentage
            if (apiData.switchType === 'Build' && data.fundsfut.length) {
              percent = data.fundsfut[k] as number
            }

            return {
              name: fund.name,
              assetCode: fund.telCode,
              percentSplit: percent || 0,
            }
          }),
  }
  return apiData
}

export async function requestInvestmentSwitch(
  userId: string,
  accountNumber: string,
  data: SwitchRequest | SwitchRequestApex,
  authToken: string
) {
  const api = getAccountsApi(authToken)
  return await api.setInvestmentStrategy({ userId, accountNumber }, { data })
}
