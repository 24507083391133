// To avoid cyclic dependencies we separate product variables from product helpers.
export enum ProductSlug {
  FFKS = 'kiwisaver',
  FFKP = 'kiwisaver-plan',
  FFMF = 'managed-funds',
  FFIF = 'investment-funds',
  FF_TWO = 'fisher-funds-two',
  FF_FUTURE = 'futureplan',
  FF_PREMIUM = 'premium-service',
  FF_LIFE = 'lifesaver',
  FF_INV = 'investment-series',
  FF_IMA = 'ima',
}

export enum ProductType {
  FFKS = 'Fisher Funds KiwiSaver Scheme',
  FFMF = 'Fisher Funds Managed Funds',
  FF_TWO = 'Fisher Funds TWO KiwiSaver',
  FF_PREMIUM = 'Fisher Funds Premium Service',
  FF_LIFE = 'Fisher Funds Lifesaver Plan',
  FF_FUTURE = 'Fisher Funds FuturePlan', // Note: not exist in salesforce Product__c as of May 2022
  FF_INV = 'Fisher Funds Investment Series',
  FFIF = 'Fisher Funds Investment Funds',
  FFKP = 'Fisher Funds KiwiSaver Plan',
}

export enum ProductCode {
  FFKS = 'KSS/10035',
  FFMF = 'TEL/00003',
  FF_TWO = 'KSS/10002',
  FF_PREMIUM = 'TEL/00004',
  FF_LIFE = 'TEL/00012',
  FF_FUTURE = 'TEL/00013',
  FF_INV = 'TEL/00002',
  FF_INSTITUTIONAL = 'TEL/00001',
  FFIF = 'FFIF',
  FFKP = 'KSS/FFKP',
}

/* Account productExternalRef tell us the type of account.
 * These are set in the API based of the type of account.
 * productExternalRef of 01 and 02 are KiwiSaver types.
 * productExternalRef of 04 through 08 are Managed Funds types.
 * See `accounts.data.ts` in the api.
 */
export const KIWISAVER_ONE = '02'
export const KIWISAVER_TWO_TEL = '01' // TODO: Once FFTWO has migrated to Apex, delete references to TEL FFTWO.
export const KIWISAVER_TWO_APEX = 'FFTWO'
export const MANAGED_FUNDS = '05'
export const INVESTMENT_SERIES = '04'
export const PREMIUM_SERVICE = '06'
export const LIFE_SAVER = '07'
export const FUTUREPLAN = '08'
export const KIWISAVER_PLAN = 'FFKP'
export const INVESTMENT_FUNDS = 'FFIF'

// Used for denoting products with the Api Bridge
export const ProductShortCodes: { [key: string]: string } = {
  [KIWISAVER_PLAN]: 'FFKP',
  [KIWISAVER_ONE]: 'FFKS',
  [KIWISAVER_TWO_TEL]: 'FFTWO', // TODO: Once FFTWO has migrated to Apex, delete references to TEL FFTWO.
  [KIWISAVER_TWO_APEX]: 'FFTWO',
  [MANAGED_FUNDS]: 'FFMF',
  [INVESTMENT_FUNDS]: 'FFIF',
  [PREMIUM_SERVICE]: 'FFPS',
  [INVESTMENT_SERIES]: 'FFIS',
  [LIFE_SAVER]: 'FFLS',
  [FUTUREPLAN]: 'FFFP',
}

export const ProductDisplayOrder = Object.keys(ProductShortCodes)
