import getApiCore from './../core'

const withdrawsPath = (fscClientId: string, accountNumber: string) =>
  `v1/withdrawal/${fscClientId}/financial-account/${accountNumber}`

type WithdrawPostBodyBankAccount = {
  name: string
  bankNumber: string
  branchNumber: string
  accountNumber: string
  accountSuffix: string
}

type WithdrawPostBodyFull = {
  type: 'Full'
}
type WithdrawPostBodyPartial = {
  type: 'Partial'
  totalWithdrawalAmount: number
}

type WithdrawPostBody = {
  useBankAccountOnFile: boolean
  newBankAccount?: WithdrawPostBodyBankAccount
} & (WithdrawPostBodyFull | WithdrawPostBodyPartial)

type PostWithdrawParams = {
  fscClientId: string
  accountNumber: string
  data: WithdrawPostBody
}

export default function getWithdrawApi(authToken: string) {
  const apiCore = getApiCore(authToken)

  return {
    postWithdraw: (params: PostWithdrawParams) =>
      apiCore.post(withdrawsPath(params.fscClientId, params.accountNumber), {
        data: {
          ...params.data,
          source: 'FFO Web',
        },
      }),
  }
}
