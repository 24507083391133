import {
  Asset,
  Fund,
  Strategy,
  StrategyCode,
} from '../components/switch-contentful-modal/SwitchContentfulModal.types'
import { ProductSlug } from '../common/product-variables'
import { STRATEGY_SALESFORCECODE, buildAssets, isGlidePath } from './strategy'
import { AccountProfile } from '../redux/account-details/account-details.model'
import { Product, productHasStrategies } from '../common/product-helper'
import {
  APEX_FUNDS_CODES,
  APEX_MIX_MANDATE_SHORTNAME,
  getIPQTargetClientSummary,
} from './fund'

export enum InvestmentOptionType {
  STRATEGY = 'strategy',
  FUND = 'fund',
}
const numeral = require('numeral')

export type AssetAllocation = {
  allocationPercentage: number
  asset: Asset
}

export type StrategyFundAllocation = {
  allocationPercentage: number
  fund: Fund
}

export interface IInvestmentOption {
  type: InvestmentOptionType
  shortName: string
  telCode?: string
  salesforceCode?: StrategyCode
  summary?: string
  inceptionDate?: Date
  suggestedTimeframe?: string
  riskLevel: number
  annualFees?: number
  incomeAssets?: AssetAllocation[]
  growthAssets?: AssetAllocation[]
  strategyFundAllocations?: StrategyFundAllocation[]
  targetClient: string
  legacy?: boolean
  legacySince?: string
  strategy?: Strategy
}

export const castToInvestmentOption = (
  strategy?: Strategy,
  fund?: Fund
): IInvestmentOption | null => {
  if (strategy) {
    const { incomeAssets, growthAssets } = buildAssets(strategy)
    return {
      type: InvestmentOptionType.STRATEGY,
      shortName: strategy.shortName,
      telCode: strategy.telCode,
      summary: strategy.summary,
      inceptionDate: strategy.inceptionDate,
      suggestedTimeframe: strategy.suggestedTimeframe,
      riskLevel: strategy.riskLevel,
      annualFees: strategy.annualFees,
      incomeAssets,
      growthAssets,
      targetClient: strategy.targetClient,
      strategy,
    }
  }
  if (fund) {
    return {
      type: InvestmentOptionType.FUND,
      shortName: fund.shortName,
      telCode: fund.telCode,
      summary: fund.summary,
      inceptionDate: fund.inceptionDate,
      suggestedTimeframe: fund.suggestedTimeframe,
      riskLevel: fund.riskLevel,
      annualFees: fund.annualFees,
      incomeAssets: fund.incomeAssets,
      growthAssets: fund.growthAssets,
      targetClient: fund.targetClient,
      legacy: fund.legacy,
      legacySince: fund.legacySince,
    }
  }
  return null
}

export const getProductPagePath = (productSlug: ProductSlug) => ({
  link: `https://fisherfunds.co.nz/${productSlug}`,
})
export const getProductInvestmentOptionsPagePath = (
  productSlug: ProductSlug,
  salesforceCode?: string
) => {
  const base = `${getProductPagePath(productSlug)?.link}/investment-options`
  return salesforceCode ? `${base}#${salesforceCode}` : base
}

export const getProductFundUpdatesPagePath = (productSlug: ProductSlug) => {
  return `${
    getProductPagePath(productSlug)?.link
  }/forms-and-documents?productId=${productSlug}&documentType=Quarterly+Fund+Updates`
}

const getFundTotalAllocationsPercentage = (assets: AssetAllocation[]) =>
  assets
    ? assets.reduce(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (total, asset) =>
          numeral(total).add(asset.allocationPercentage).value()!,
        0
      )
    : 0

const getStrategyTotalAllocationsPercentage = (
  allocations: any,
  assetsKey: 'incomeAssets' | 'growthAssets'
) =>
  allocations?.reduce((total: number, allocation: any) => {
    const assetsTotal = getFundTotalAllocationsPercentage(
      allocations[0]?.fund[assetsKey]
    )
    return total + (assetsTotal * allocation.allocationPercentage) / 100
  }, 0)

export const getInvestmentOptionAssetMix = (
  investmentOption: IInvestmentOption
) => {
  if (investmentOption.strategy) {
    const allocations = investmentOption.strategy.strategyFundAllocations

    const incomeAssetsPercentage = getStrategyTotalAllocationsPercentage(
      allocations,
      'incomeAssets'
    )
    const growthAssetsPercentage = getStrategyTotalAllocationsPercentage(
      allocations,
      'growthAssets'
    )

    return {
      incomeAssetsPercentage,
      growthAssetsPercentage,
    }
  }

  return {
    incomeAssetsPercentage: getFundTotalAllocationsPercentage(
      investmentOption.incomeAssets
    ),
    growthAssetsPercentage: getFundTotalAllocationsPercentage(
      investmentOption.growthAssets
    ),
  }
}

export const getInvestmentOptionNameByTelCode = (
  investmentOptions: { shortName: string; telCode?: string }[],
  telCode: string
): string | undefined => {
  const investmentOption = investmentOptions.find((f) => f.telCode === telCode)

  return investmentOption?.shortName
}

export const findStrategyByCode = (strategies: Strategy[], code: string) => {
  return strategies.find(
    (s: Strategy) => s.telCode === code || s.salesforceCode === code
  )
}

export const findFundByCode = (funds: Fund[], telCode: string) => {
  return funds.find((f: Fund) => f?.telCode === telCode)
}

/**
 * @param code telCode or salesforceCode
 * @returns InvestmentOption
 */
export const getInvestmentOptionByCode = (product: Product, code: string) => {
  if (code === APEX_FUNDS_CODES.CUSTOM_CODE) {
    if (!productHasStrategies(product.slug)) {
      return {
        telCode: code,
        shortName: APEX_MIX_MANDATE_SHORTNAME,
        targetClient: getIPQTargetClientSummary(code),
      } as IInvestmentOption
    }
    return castToInvestmentOption(
      product.strategies.find(
        (s: Strategy) => s.salesforceCode === STRATEGY_SALESFORCECODE.CUSTOM
      )
    )
  }

  const isCustomOrGlidePath = [
    STRATEGY_SALESFORCECODE.CUSTOM,
    STRATEGY_SALESFORCECODE.GLIDEPATH,
  ].includes(code)
  if (isCustomOrGlidePath) {
    return castToInvestmentOption(
      product.strategies.find((s: Strategy) => s.salesforceCode === code)
    )
  }
  if (productHasStrategies(product.slug) && code) {
    return castToInvestmentOption(findStrategyByCode(product.strategies, code))
  }
  return castToInvestmentOption(null, findFundByCode(product.funds, code))
}

/**
 * Finding out if an investor profile matches a strategy OR fund.
 * Most strategies will match by telCode but GlidePath is a special case becasue
 * as time goes by GlidePath changes and so it's telCode so instead of matching
 * with a bunch of TEL codes we're gonna compare the strategy name.
 * Also strategies TEL code comes from Contentful so there is no easy way to add
 * a list of codes for a single strategy unless we do it in code. So strategy
 * name comparison does it.
 */
export const investmentOptionMatchesProfile = (
  option: Strategy | Fund,
  profile: AccountProfile
) => {
  if (!profile) {
    return false
  }
  const { profileCode, profileName } = profile
  //checking for glidepath
  if ('salesforceCode' in option && option.salesforceCode === 'GlidePath') {
    return isGlidePath(option) && profileName.includes('GlidePath')
  }
  return !!profileCode && profileCode === option.telCode
}

export const getFilteredInvestmentOptions = (product: Product) => {
  if (product.strategies) {
    return getFilteredStrategies(product.strategies)
  }
  return getFilteredFunds(product.funds)
}

export const getFilteredStrategies = (strategies: Strategy[]) =>
  strategies.filter((strategy) => !strategy.customStrategy)
export const getFilteredFunds = (funds: Fund[]) =>
  funds.filter((fund) => !fund.legacy && !fund.isSuspended)

export const removeStrategyOrFundFromEnding = (shortName: string) =>
  shortName
    .replace(/strategy$/i, '')
    .replace(/fund$/i, '')
    .trim()

export const isCustomProfile = (profileCode: string) =>
  [STRATEGY_SALESFORCECODE.CUSTOM, APEX_FUNDS_CODES.CUSTOM_CODE].includes(
    profileCode
  )

export const isCustomInvestment = ({
  salesforceCode,
  telCode,
  strategy,
}: IInvestmentOption) =>
  salesforceCode === STRATEGY_SALESFORCECODE.CUSTOM ||
  telCode === APEX_FUNDS_CODES.CUSTOM_CODE ||
  strategy?.customStrategy

export const getEquivalentFundCode = (newInvestmentCode: string) => {
  const newOptionIsBalancedorDefaultFFKP =
    newInvestmentCode === APEX_FUNDS_CODES.FFKP_BALANCED ||
    newInvestmentCode === APEX_FUNDS_CODES.FFKP_DEFAULT
  if (!newOptionIsBalancedorDefaultFFKP) {
    return null
  }
  const equivalentFundCode =
    newInvestmentCode === APEX_FUNDS_CODES.FFKP_DEFAULT
      ? APEX_FUNDS_CODES.FFKP_BALANCED
      : APEX_FUNDS_CODES.FFKP_DEFAULT
  return equivalentFundCode
}

/**
 * Most times it'll just return property shortName but for custom strategies
 * we return fixed values. This function could be removed if we get these
 * two strategies in Contentful and with a good value in shortName.
 */
export const getShortName = (investmentOption: IInvestmentOption) => {
  const salesforceCode =
    investmentOption.strategy?.salesforceCode || investmentOption.salesforceCode
  if (salesforceCode === STRATEGY_SALESFORCECODE.CUSTOM) {
    return 'Custom Strategy'
  }
  if (investmentOption.telCode === APEX_FUNDS_CODES.CUSTOM_CODE) {
    return APEX_MIX_MANDATE_SHORTNAME
  }
  return investmentOption.shortName
}

export const getIsCustomStrategy = (
  investmentOption: IInvestmentOption
): boolean => getShortName(investmentOption) === 'Custom Strategy'
