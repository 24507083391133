import * as React from 'react'
import { Notification } from 'shared'

interface errorProps {
  isVisible: boolean
  hasSucceed: boolean
  successMessage: string
  hasError: boolean
  errorMessage: string
  onClose: () => void
}

export default (props: errorProps) => {
  if (!props.isVisible) {
    return null
  }

  if (props.hasSucceed && props.successMessage) {
    return <Notification type="success">{props.successMessage}</Notification>
  } else if (props.hasError && props.errorMessage) {
    return <Notification type="error">{props.errorMessage}</Notification>
  }

  return null
}
