/* eslint-disable react/no-this-in-sfc */
import React, { createRef, useEffect, useState } from 'react'
import {
  BankAccountInputSection,
  BankAccountInputSectionProps,
} from './BankAccountInputSection'
import './BankAccountInput.scss'

export type BankAccountInputSectionConfiguration = {
  length: number
  name: string
  pad?: boolean
}

export type BankAccountInputProps = {
  account: string
  error?: boolean
  sections?: BankAccountInputSectionConfiguration[]
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
  onChange: (account: string) => void
  separator?: string
} & Pick<BankAccountInputSectionProps, 'disabled'>

const setCursor = (
  ref: React.RefObject<HTMLInputElement>,
  position?: number
) => {
  ref.current?.setSelectionRange(position || 0, position || 0)
}

const parentContains = (parent: HTMLElement, child: HTMLElement): boolean => {
  if (!child || !child.parentElement) {
    return false
  }
  if (child.parentElement === parent || child === parent) {
    return true
  }

  return parentContains(parent, child.parentElement)
}

/**
 * TODO add this to controlledInputs once it gets moved into 'shared'
 *
 * @param account
 * @param error
 * @param onBlur
 * @param sections
 * @param onChange
 * @param separator
 * @constructor
 */
export const BankAccountInput = ({
  account,
  error,
  onBlur,
  sections = [
    { name: 'Bank', length: 2 },
    { name: 'Branch', length: 4 },
    { name: 'Account', length: 7 },
    { name: 'Suffix', length: 3, pad: true },
  ],
  onChange,
  separator = '',
  disabled,
}: BankAccountInputProps) => {
  const [refs, setRefs] = useState<React.RefObject<HTMLInputElement>[]>([])
  const [pasted, setPasted] = useState(false)
  // Dynamic create REFs
  useEffect(() => {
    if (refs.length !== sections.length) {
      setRefs(sections?.map(() => createRef<HTMLInputElement>()))
    }
  }, [refs, setRefs, sections])

  const setCursorEnd = () => {
    //set focus to first field if account is empty
    if (!account) {
      return refs[0].current.focus()
    }
    let count = 0
    for (let i = 0; i < sections.length; i += 1) {
      if (account.length <= count + sections[i].length) {
        const ref = refs[i]
        ref.current.focus()
        setCursor(ref, ref.current?.value.length || 0)
        return
      }
      count += sections[i].length
    }
  }

  // Reset cursor AFTER paste
  useEffect(() => {
    if (pasted) {
      setCursorEnd()
      setPasted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const maxNumOfDigits = sections.reduce((acc, curr) => acc + curr.length, 0)

  const handleInputSectionChange = (
    v: string,
    substringStartIndex: number,
    substringEndIndex: number
  ) => {
    const newValue = `${account?.slice(
      0,
      substringStartIndex
    )}${v}${account?.slice(substringEndIndex)}`
      .replace(/[^0-9]/g, '')
      .slice(0, maxNumOfDigits)

    if (separator !== '') {
      let startIndex = 0
      const sectionValues = sections
        .map((section) => {
          const sectionStartIndex = startIndex
          const sectionEndIndex = startIndex + section.length
          startIndex += section.length

          return newValue.slice(sectionStartIndex, sectionEndIndex)
        })
        .filter((sectionValue) => sectionValue !== '')

      onChange(sectionValues.join(separator))
    } else {
      onChange(newValue)
    }
  }

  let substringStartIndex = 0
  const inputSections = sections.map((section, sectionIndex) => {
    const sectionStartIndex = parseInt(substringStartIndex.toString(), 10)
    const sectionEndIndex = sectionStartIndex + section.length
    const value = account?.slice(sectionStartIndex, sectionEndIndex)

    const element = (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={section.name + sectionIndex}>
        <BankAccountInputSection
          tabIndex={sectionIndex === 0 ? 0 : -1}
          inputRef={refs[sectionIndex]}
          prevRef={refs[sectionIndex - 1]}
          nextRef={refs[sectionIndex + 1]}
          value={value}
          onClick={(e) => {
            e.stopPropagation()
            if (e.currentTarget.value === '') {
              setCursorEnd()
            }
          }}
          onPaste={() => {
            setPasted(true)
          }}
          onChange={(v) =>
            handleInputSectionChange(v, sectionStartIndex, sectionEndIndex)
          }
          label={section.name}
          maxLength={section.length}
          disabled={disabled}
        />
        {sectionIndex < sections.length - 1 && (
          <p
            className={`bank-account-input__section-separator${
              account?.length > sectionEndIndex ? '--dark' : ''
            }`}
          >
            -
          </p>
        )}
      </React.Fragment>
    )
    substringStartIndex += section.length
    return element
  })

  const getAccountWithPadding = () => {
    let minLength = 0
    return sections
      .map((section, i) => {
        const val =
          section.pad && account?.length >= minLength
            ? refs[i].current?.value.padStart(section.length, '0')
            : refs[i].current?.value

        minLength += section.length
        return val
      })
      .join(separator)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`bank-account-input__container${error ? '--error' : ''}`}
      onClick={setCursorEnd}
      onKeyDown={(e) =>
        e.key === 'Backspace' || (e.key === 'Space' && setCursorEnd())
      }
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e)
        }
        if (sections.some((s) => s.pad)) {
          const target = e.relatedTarget
          const parent = e.currentTarget

          if (!parentContains(parent, target as HTMLElement)) {
            onChange(getAccountWithPadding())
          }
        }
      }}
    >
      {inputSections}
    </div>
  )
}
