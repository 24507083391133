import Alert from 'react-s-alert'
import { Notification, NotificationType } from 'shared'

type SiteAlertProps = {
  message: string
  id: number
  condition?: NotificationType
}

const SiteAlert = (props: SiteAlertProps) => (
  <Notification
    backgroundColor="white"
    type={props.condition}
    onClose={() => Alert.close(props.id)}
  >
    {props.message}
  </Notification>
)

export default SiteAlert
