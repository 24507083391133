import { useMemo } from 'react'
import { Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import format from 'date-fns/format'
import { HorizontalBar } from 'react-chartjs-2'
import { marked } from 'marked'
import { ChartData, ChartDataSets } from 'chart.js'
import {
  AssetAllocation,
  IInvestmentOption,
} from '../../utils/investmentOption'
import {
  CHART_COLOURS,
  horizontalBarChartOptions,
} from '../../common/graph-helper'
import './InvestmentOptionInfoDetail.scss'
import { StrategyRiskIndicator } from '../strategy-risk-indicator/StrategyRiskIndicator'
import { useInvestmentReturnSummary } from '../../api/returns/useInvestmentReturnSummary'
import { Product } from '../../common/product-helper'
import differenceInYears from 'date-fns/differenceInYears'
import { formatPercentage } from '../../common/currency-helper'
import { RiskTooltip } from '../risk-tooltip-content/RiskTooltip'

type AssetArrayItem = {
  value: number
  info: string
  infoIndex: number
}

const roundSplit = function (x: number): number {
  return parseFloat(x.toFixed(1))
}

const mergeObjects = (
  data: AssetAllocation[]
): { [key: string]: AssetArrayItem } => {
  const result = {}

  data?.forEach((asset: any) => {
    const key = asset.asset.name
    const value = asset.allocationPercentage
    const info = asset.asset.additionalInfo
    if (result[key]) {
      result[key] = { ...result[key], value: result[key].value + value }
    } else {
      result[key] = { value, info }
    }
  })
  return result
}

const Assets = ({
  assets,
  keyOffset = 0,
}: {
  assets: { [key: string]: AssetArrayItem }
  keyOffset?: number
}) => (
  <>
    {Object.keys(assets).map((assetArrayName, k) => (
      <div className="asset-info" key={assetArrayName}>
        <label className="asset-label">
          <div
            style={{
              width: 10,
              height: 10,
              background: CHART_COLOURS[k + keyOffset],
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          {assetArrayName}
          {assets[assetArrayName].info && (
            <>
              {[...Array(assets[assetArrayName].infoIndex)].map((_, k) => (
                <span key={k}>*</span>
              ))}
            </>
          )}
        </label>
        <span className="asset-split">
          {roundSplit(assets[assetArrayName].value)}%
        </span>
      </div>
    ))}
  </>
)

const COMMON_BAR_DATASET_PROPS: Partial<ChartDataSets> = {
  barThickness: 10,
  stack: 'all',
  borderColor: 'white',
  borderWidth: () => ({ right: 1 } as any),
}

type InvestmentOptionInfoDetailProps = {
  investmentOption: IInvestmentOption
  product: Product
}

const InvestmentOptionInfoDetail = (props: InvestmentOptionInfoDetailProps) => {
  const { investmentOption, product } = props
  const { returnsSummary } = useInvestmentReturnSummary(
    product,
    investmentOption
  )

  const isOlderThan5Years = useMemo(
    () =>
      !!returnsSummary?.inceptionDate &&
      differenceInYears(new Date(), new Date(returnsSummary.inceptionDate)) >=
        5,
    [returnsSummary?.inceptionDate]
  )
  const annualisedReturn = useMemo(
    () =>
      isOlderThan5Years
        ? returnsSummary?.return5Years
        : returnsSummary?.returnSinceInception,
    [isOlderThan5Years, returnsSummary]
  )

  const incomeAssetsObject = mergeObjects(investmentOption.incomeAssets)
  const growthAssetsObject = mergeObjects(investmentOption.growthAssets)
  const assets = []
    .concat(Object.entries(incomeAssetsObject))
    .concat(Object.entries(growthAssetsObject))

  // get list of assets with info
  const assetsWithInfo: any = []
  const datasets: ChartDataSets[] = assets.map(([label, asset], index) => {
    if (asset.info) {
      assetsWithInfo.push({
        label,
        info: asset.info,
      })
      asset.infoIndex = Object.keys(assetsWithInfo).length
    }

    const assetDataset = {
      data: [asset.value],
      label: label,
      backgroundColor: CHART_COLOURS[index],
      ...COMMON_BAR_DATASET_PROPS,
    }

    const isLast = index === assets.length - 1
    return isLast ? { ...assetDataset, borderWidth: 0 } : assetDataset
  })

  const chartData: ChartData = {
    labels: [],
    datasets,
  }

  const firstFund = investmentOption.strategyFundAllocations?.[0].fund
  const firstFundOrSummary =
    investmentOption.strategyFundAllocations?.length === 1
      ? firstFund
      : investmentOption
  const { summary, targetClient } = firstFundOrSummary
  const suggestedTimeframe = investmentOption.suggestedTimeframe
    ?.toString()
    ?.trim()

  const informationLink = investmentOption.strategy?.informationLink

  return (
    <>
      {investmentOption.strategy?.salesforceCode === 'GlidePath' ? (
        <div>
          {!!summary && (
            <div
              dangerouslySetInnerHTML={{
                __html: marked.parse(summary),
              }}
            />
          )}
          {!!informationLink && (
            <p>
              Learn more about{' '}
              <a
                target="_blank"
                href={informationLink}
                rel="noopener noreferrer"
              >
                GlidePath
              </a>
              .
            </p>
          )}
        </div>
      ) : (
        <div>
          <h6>Summary of investment objectives and strategy</h6>
          {!!summary && (
            <div
              className="investment-summary-info"
              dangerouslySetInnerHTML={{
                __html: marked.parse(summary),
              }}
            />
          )}

          {!!summary && !!targetClient && <hr />}

          {!!targetClient && (
            <>
              <h6>Who is the fund suitable for?</h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parse(targetClient),
                }}
              />
            </>
          )}

          <hr />
          <h6>Target investment mix</h6>
          <HorizontalBar
            height={5}
            data={chartData}
            options={horizontalBarChartOptions}
            legend={{ display: false }}
          />

          <div className="assets-list">
            <div>
              <p className="mt-sm mb-xs text-bold">Income Assets</p>
              <Assets assets={incomeAssetsObject} />
            </div>

            <div>
              <p className="mt-sm mb-xs text-bold">Growth Assets</p>
              <Assets
                assets={growthAssetsObject}
                keyOffset={Object.keys(incomeAssetsObject).length}
              />
            </div>

            {assetsWithInfo.length !== 0 && (
              <div className="assets-description">
                {assetsWithInfo.map((asset: any, k: any) => (
                  <p className="mb-0" key={k}>
                    {[...Array(k + 1)].map(() => (
                      <span key={k}>*</span>
                    ))}{' '}
                    {asset.label} - {asset.info}
                  </p>
                ))}
              </div>
            )}
          </div>

          {!!suggestedTimeframe && (
            <>
              <hr />
              <h6>Suggested minimum timeframe for this investment</h6>
              {investmentOption.suggestedTimeframe?.toString()?.trim() || '--'}
            </>
          )}

          {!!investmentOption.riskLevel && (
            <Tooltip
              title={
                <RiskTooltip
                  value={investmentOption.riskLevel as number}
                  name={investmentOption.shortName}
                />
              }
              disableFocusListener
              enterTouchDelay={200}
            >
              <div className="tooltip">
                <hr />
                <span className="tooltip-label">Risk indicator</span>
                <span className="tooltip-icon">
                  <InfoIcon />
                </span>
                <StrategyRiskIndicator
                  className="risk-indicator mt-md"
                  minimum={1}
                  maximum={7}
                  value={investmentOption.riskLevel}
                />
              </div>
            </Tooltip>
          )}

          {!!annualisedReturn && (
            <Tooltip
              title={
                <>
                  {!!investmentOption.inceptionDate && (
                    <p className="MuiTooltip-tooltip">
                      * Inception Date:{' '}
                      {format(
                        new Date(investmentOption?.inceptionDate),
                        'dd MMM yyyy'
                      )}
                    </p>
                  )}
                  {returnsSummary?.priceDate && (
                    <p className="MuiTooltip-tooltip">
                      After fees and before tax returns as at{' '}
                      {format(
                        new Date(returnsSummary.priceDate),
                        'dd MMM yyyy'
                      )}
                      . Fund performance figures have been annualised where the
                      performance period is more than one year. Fund performance
                      figures do not include entry fees, exit fees or brokerage
                      fees or tax, but do include any tax credits applicable to
                      the funds since October 2007. Please note that past
                      performance is not necessarily indicative of future
                      returns. Returns can be positive or negative, and returns
                      over different time periods may vary. No returns are
                      promised or guaranteed.
                    </p>
                  )}
                </>
              }
              disableFocusListener
              enterTouchDelay={200}
            >
              <div className="tooltip">
                <hr />
                <span className="tooltip-label">
                  {' '}
                  {`Annualised ${
                    isOlderThan5Years
                      ? '5 year return'
                      : 'return since inception'
                  }`}
                </span>
                <span className="tooltip-icon">
                  <InfoIcon />
                </span>
                <p>{formatPercentage(annualisedReturn)}</p>
              </div>
            </Tooltip>
          )}

          {!!investmentOption.annualFees && (
            <Tooltip
              title={
                <p className="MuiTooltip-tooltip">
                  You will be charged fees for investing in Fisher Funds
                  investments. Fees are deducted from your investment and will
                  reduce your returns. If Fisher Funds invests in other funds,
                  those funds may also charge fees.
                </p>
              }
            >
              <div className="tooltip">
                <hr />
                <span className="tooltip-label">Annual fund charge</span>
                <span className="tooltip-icon">
                  <InfoIcon />
                </span>
                <p>{investmentOption.annualFees.toFixed(2)}%</p>
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </>
  )
}

export default InvestmentOptionInfoDetail
