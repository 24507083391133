import { datadogRum } from '@datadog/browser-rum'

export type UserInfo = {
  id?: string
}

const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV

export const initDatadog = () => {
  if (!!DATADOG_ENV) {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'ffo-test-portal-frontend',
      env: process.env.REACT_APP_DATADOG_ENV,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      enablePrivacyForActionName: true,
    })
  }
}

export const setDatadogUser = (userInfo: UserInfo) => {
  datadogRum.setUser(userInfo)
}
