import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { routerActions } from 'react-router-redux'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Divider from '@material-ui/core/Divider'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import startOfYear from 'date-fns/startOfYear'
import moment from 'moment'
import isNumber from 'lodash/isNumber'
import { Button } from 'shared'
import './AccountDetailsInformation.scss'
import { getAccountByID } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { AccountDetail } from '../../redux/account-details/account-details.model'
import { AppState } from '../../redux/app-state'
import { AccountDetailsActions } from '../../redux/account-details/account-details.actions'
import { SwitchNotificationsRequestAction } from '../../redux/switch-notifications/switch-notifications.actions'
import CurrencyTotal from '../currency/CurrencyTotal'
import AccountDetailsAddFundsButton from '../account-details-add-funds-button/AccountDetailsAddFundsButton'
import { AccountDetailsReturn } from './AccountDetailsReturn'
import CurrencyDifference from '../currency/CurrencyDifference'
import { useGovernmentContribution } from '../accounts-overview/useGovernmentContribution'
import { EligibleView } from '../government-contribution/EligibleView'

export interface AccountDetailsInformationProps {
  currentAccount?: Account
  details?: AccountDetail
  nextStep?: (path: string) => void
  accountId?: string
  hasError?: boolean
  errorMessage?: string
  switchNotifications?: Notification[]
  loadSwitchNotifications?: () => void
  marketEarnings?: number
}

const PEFORMANCE_RETURNS_TOOLTIP =
  'Performance returns are calculated using the Money-Weighted Return method, which factors in both the timing and size of cash flows.'

function AccountDetailsInformation(props: AccountDetailsInformationProps) {
  useEffect(() => {
    props.loadSwitchNotifications()
  })

  const openInvestmentTabs = () => {
    props.nextStep(`/accounts/${props.currentAccount.accountNumber}/funds`)

    setTimeout(() => {
      const element: HTMLDivElement = document.getElementById(
        'account-tabs'
      ) as HTMLDivElement

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 250)
  }

  const { currentAccount: account } = props

  const {
    governmentContributionDetail,
    commenceDate,
    isDepositMode,
    kiwiSaverOriginalStart,
  } = account

  const renderReturns = () => {
    if (isDepositMode) {
      return null
    }

    const { marketEarnings } = props
    const { annualisedPercentageReturn, yearToDatePercentageReturn } = account
    const firstJan = startOfYear(new Date())
    const accountStart = new Date(account.commenceDate)
    const yearStartOrAccountStart = isAfter(accountStart, firstJan)
      ? accountStart
      : firstJan

    if (
      !!marketEarnings &&
      !annualisedPercentageReturn &&
      !yearToDatePercentageReturn
    ) {
      return (
        <>
          <Divider />
          <div className="account-details-information__numbers">
            <div className="flex-row mb-xs">
              <p className="details-label account-details-information__returns__label">
                Since inception return
              </p>
              {isNumber(marketEarnings) ? (
                <CurrencyDifference value={marketEarnings} />
              ) : (
                <div className="account-details-information__returns__loading-shimmer my-auto" />
              )}
            </div>
            <div className="flex-row mb-xs">
              <p className="text-large details-description">
                After fees and tax
              </p>
              {!!account.commenceDate && (
                <p className="text-large details-description color-smoke">
                  {'since ' +
                    moment(account.commenceDate).format('DD MMM YYYY')}
                </p>
              )}
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="account-details__returns">
          {isNumber(marketEarnings) ? (
            <AccountDetailsReturn
              value={marketEarnings}
              type="CURRENCY"
              label="Since inception return"
              description="After fees and tax"
            />
          ) : (
            <div className="account-details-information__returns__loading-shimmer my-auto" />
          )}
          {!!annualisedPercentageReturn && (
            <AccountDetailsReturn
              value={annualisedPercentageReturn}
              type="PERCENTAGE"
              label="Average return"
              description="Since your first investment"
              tooltip={PEFORMANCE_RETURNS_TOOLTIP}
            />
          )}
          {!!yearToDatePercentageReturn && (
            <AccountDetailsReturn
              value={yearToDatePercentageReturn}
              type="PERCENTAGE"
              label="Year to date return"
              description={
                'since ' + format(yearStartOrAccountStart, 'd MMM yyyy')
              }
              tooltip={PEFORMANCE_RETURNS_TOOLTIP}
            />
          )}
        </div>
      </>
    )
  }

  const RenderGovernmentContributionDetail = () => {
    const { isEligible } = useGovernmentContribution({
      governmentContributionDetail: governmentContributionDetail,
      commenceDate: commenceDate,
      kiwiSaverOriginalStart: kiwiSaverOriginalStart,
    })

    if (!isEligible) {
      return null
    }

    return (
      <>
        <Divider className="mt-0" />
        <EligibleView
          governmentContributionDetail={{
            ...governmentContributionDetail,
          }}
          commenceDate={commenceDate}
          kiwiSaverOriginalStart={kiwiSaverOriginalStart}
          accountId={account.accountID}
          externalRef={account.productExternalRef}
          productExternalName={account.productExternalName}
        />
      </>
    )
  }

  return (
    <div className="account-details-information">
      <div className="account-details-information__balance">
        <div className="account-details-information__balance-information">
          <Typography
            component="h6"
            variant="caption"
            className="current-balance-title"
          >
            Current balance
          </Typography>
          <p className="account-details-information__total">
            <CurrencyTotal value={account.accountTotal} />
          </p>
          <p className="text-large details-description">
            {account.latestPriceDate
              ? 'as at ' +
                format(new Date(account.latestPriceDate), 'dd MMM yyyy')
              : 'at most recent valuation'}
          </p>
        </div>
        <div className="account-details-information__balance-actions">
          <AccountDetailsAddFundsButton />
          {!isDepositMode && (
            <Button
              variant="link"
              iconRight={ArrowForwardIcon}
              onClick={openInvestmentTabs}
              className="view-unit-information mt-md"
            >
              View unit information
            </Button>
          )}
        </div>
      </div>
      {renderReturns()}
      {governmentContributionDetail && RenderGovernmentContributionDetail()}
    </div>
  )
}

const mapStateToProps = (
  state: AppState,
  props: AccountDetailsInformationProps
) => {
  const cachedAccountDetails = state.accountDetails.accounts.find(
    (acc: AccountDetail) => acc.accountID === props.accountId
  )

  return {
    currentAccount: getAccountByID(props.accountId, state.accounts.accounts),
    marketEarnings: cachedAccountDetails?.marketEarnings,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountDetailsActions>) => ({
  loadSwitchNotifications: bindActionCreators(
    SwitchNotificationsRequestAction,
    dispatch
  ),
  nextStep: (path: string) => dispatch(routerActions.push(path)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(AccountDetailsInformation)
