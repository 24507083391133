import { useEffect } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ReportFilters as ReportFiltersModel } from '../../redux/reports/reports.model'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ReportFilters from '../../components/report-filters/ReportFilters'
import ReportList from '../../components/report-list/ReportList'
import SpinnerOnLoad from '../../components/spinner-on-load/SpinnerOnLoad'
import { AppState } from '../../redux/app-state'
import {
  ReportsActions,
  ReportsRequestAction,
  ReportsRequestPayload,
} from '../../redux/reports/reports.actions'
import InfoMessage from '../../components/info-message/InfoMessage'
import { Account } from '../../redux/accounts/accounts.model'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
} from '../../redux/layout/layout.actions'
import { Notification } from 'shared'
import './Reports.scss'

export interface ReportsProps {
  reports: {
    reports: Report[]
    isLoading: boolean
  }
  filters?: ReportFiltersModel
  setActiveAccountTab?: (value: string) => void
  setActiveMenu: (activeMenu: string) => void
  userId: string
  accounts: Account[]
  actions: {
    getReports: (payload: ReportsRequestPayload) => void
    next: (path: string) => void
  }
}

export type Report = {
  date: string
  title: string
  type: string
  url: string
  accountId: string
}

export function Reports(props: ReportsProps) {
  useEffect(() => {
    const mainContent = document.querySelector('.main-content')

    if (mainContent) {
      window.scrollTo(0, 0)
      mainContent.scrollTo(0, 0)
    }

    props.setActiveMenu('reports')
    props.setActiveAccountTab('')

    const offset: number = 0
    if (SHOW_REPORTS) {
      // get all reports
      props.actions.getReports({ userId: props.userId, offset })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SHOW_REPORTS = process.env.REACT_APP_DISABLE_REPORTS !== 'true'

  return (
    <Container
      maxWidth={false}
      className="reports-container main-content-padding"
    >
      <Paper elevation={0}>
        <Typography variant="h5" className="mb-lg">
          My reports
        </Typography>

        {SHOW_REPORTS ? (
          <SpinnerOnLoad isLoading={props.reports.isLoading}>
            <ReportFilters />

            {props.reports.reports.length > 0 ? (
              <ReportList
                reports={props.reports.reports}
                filters={props.filters}
              />
            ) : (
              <Notification backgroundColor="grey">
                No reports currently exist for your accounts.
              </Notification>
            )}
          </SpinnerOnLoad>
        ) : (
          <InfoMessage>
            Reports are currently unavailable, we are working to resolve the
            issue. Apologies for any inconvenience.
          </InfoMessage>
        )}
      </Paper>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    userId: state.user.userid,
    reports: {
      reports: state.reports.reports.map((report) => ({
        ...report,
        url: `${process.env.REACT_APP_FFM_ONLINE_API_URL!}/api/users/${
          state.user.userid
        }/reports/${report.id}?accountNumber=${report.accountId}`,
      })),
      isLoading: state.reports.isLoading,
    },
    filters: state.reports.filters,
    accounts: state.accounts.accounts,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ReportsActions>) => ({
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  actions: {
    getReports: bindActionCreators(ReportsRequestAction, dispatch),
    next: (path: string) => dispatch(routerActions.push(path)),
  },
})

export default connect(mapStateToProps, mapDispatchToProps, null)(Reports)
