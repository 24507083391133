import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SwitchNotification } from '../../redux/switch-notifications/switch-notifications.model'
import { Notification } from 'shared'
import cn from 'classnames'
import Link from '../clickable/link/Link'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

type InvestmentSwitchNotificationProps = {
  className?: string
  productName: string
  isApexProduct: boolean
  switchNotifications: SwitchNotification[]
  isStrategyProduct: boolean
}
const InvestmentSwitchNotification = ({
  className,
  productName,
  isApexProduct,
  switchNotifications = [],
  isStrategyProduct,
}: InvestmentSwitchNotificationProps) => {
  const renderApexOrTelTimeframe = () => {
    if (isApexProduct) {
      return `Please note - A change to your ${
        isStrategyProduct ? 'strategy' : 'fund'
      } may take up to three business days to be processed.`
    }
    return `Please note - any requests received after 3pm may not be processed until the
     next working day. Your ${productName} is an investment and a change to how
     your funds are invested will take a minimum of 2 business days to be
     completed.`
  }

  const renderUnitPricingDisclaimer = () => {
    return 'The unit price is calculated after both local and overseas markets have been closed, so an investment switch processed on Monday, for example, can only be completed once all market have closed - which would normally fall on the Wednesday'
  }
  return (
    <>
      {switchNotifications.length > 0 && (
        <div className="notifications-container">
          {switchNotifications.map((n) => {
            const actions =
              n.linkUrl && n.linkText ? (
                <Link
                  variant="link"
                  color="primary"
                  iconRight={ArrowForwardIcon}
                  to={n.linkUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {n.linkText}
                </Link>
              ) : null

            return (
              <Notification
                key={n.id}
                type="info"
                backgroundColor="grey"
                actions={actions}
              >
                <p className="color-midnight">
                  <b>{n.title}</b>
                </p>
                {documentToReactComponents(n.message)}
              </Notification>
            )
          })}
        </div>
      )}
      <Notification className={cn({ [`${className}`]: !!className }, 'mb-md')}>
        {renderApexOrTelTimeframe()}
        {!isApexProduct && renderUnitPricingDisclaimer()}
      </Notification>
    </>
  )
}

export default InvestmentSwitchNotification
