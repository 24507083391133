import { useFormik } from 'formik'
import numeral from 'numeral'
import {
  Button,
  InputWrapper,
  ControlledAmountInput,
  ControlledInput,
  ControlledRadioMenu,
  Link,
} from 'shared'
import './WithdrawFundsForm.scss'
import { WithdrawalFormFields, WithdrawalTypeEnum } from './WithdrawFundsModal'
import { BankAccountInput } from '../../shared-components/form/BankAccountInput'
import { schemaValidation } from '../../utils'
import { withdrawalFormSchema } from './WithdrawFundsForm.schema'
import { Account } from '../../redux/accounts/accounts.model'
import { isInvestmentFundsAccount } from '../../common/accounts-helper'
import LoadingSpinner from '../loading-spinner/LoadingSpinner'

type WithdrawFundsFormProps = {
  account: Account
  handleSubmit: (values: WithdrawalFormFields) => void
  handleBack: () => void
  isDisable?: boolean
}

const MINIMUM_ACCOUNT_BALANCE = 100

const WithdrawFundsForm = ({
  account,
  handleSubmit,
  handleBack,
  isDisable,
}: WithdrawFundsFormProps) => {
  const accountTotalMinusMinimumBalance = numeral(account?.accountTotal)
    .subtract(MINIMUM_ACCOUNT_BALANCE)
    .value()

  const initialValues: WithdrawalFormFields = {
    product: account.productExternalRef,
    accountId: account.accountID,
    withdrawalType: WithdrawalTypeEnum.Partial,
    withdrawalAmount: null,
    useExistingBankAccount: true,
    bankAccountName: null,
    bankAccountNumber: null,
  }
  const form = useFormik({
    initialValues: { ...initialValues },
    validate: schemaValidation<WithdrawalFormFields>(
      withdrawalFormSchema(accountTotalMinusMinimumBalance)
    ),
    onSubmit: () => {
      handleSubmit(form.values)
    },

    validateOnMount: true,
  })

  const isInvestmentFunds = isInvestmentFundsAccount(account)

  const handleFieldChange = () => {
    if (form.values.withdrawalType === WithdrawalTypeEnum.Full) {
      form.setFieldValue('withdrawalAmount', null)
    }
    if (form.values.useExistingBankAccount) {
      form.setFieldValue('bankAccountName', null)
      form.setFieldValue('bankAccountNumber', null)
    }
  }

  const isPartialWithdrawal =
    form.values.withdrawalType === WithdrawalTypeEnum.Partial

  return (
    <>
      <div className="form-container">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleFieldChange()
            form.handleSubmit()
          }}
        >
          <div className="form-container-section">
            <h6>Withdrawal type and amount </h6>
            <div className="form-container-section withdrawal">
              <ControlledRadioMenu
                className={`text-bold ml-md ${
                  isPartialWithdrawal ? '' : ' pb-md'
                }`}
                direction="vertical"
                form={form}
                options={[
                  {
                    label: 'Full Withdrawal',
                    value: WithdrawalTypeEnum.Full,
                    subLabel: !isPartialWithdrawal ? (
                      <div>
                        <p className="form-container-section-sub-label text-small text-thin">
                          <em>
                            {`If you make a full withdrawal, your account will be closed. To keep your account open you must leave a minimum of $${MINIMUM_ACCOUNT_BALANCE} in the account.`}
                          </em>
                        </p>
                        <hr />
                      </div>
                    ) : (
                      <hr className="mb-0" />
                    ),
                  },
                  {
                    label: 'Partial Withdrawal',
                    subLabel: isPartialWithdrawal && (
                      <div>
                        <p className="form-container-section-sub-label text-small text-thin ml-xl">
                          <em>
                            {`$${accountTotalMinusMinimumBalance}
                          available`}
                          </em>
                        </p>
                        <p className="form-container-section-sub-label text-small text-thin">
                          <em>
                            This excludes $100 which must be left in your
                            account for it to remain open.
                          </em>
                        </p>
                      </div>
                    ),
                    value: WithdrawalTypeEnum.Partial,
                  },
                ]}
                name={'withdrawalType'}
                disabled={isDisable}
              />
              {isPartialWithdrawal && (
                <div className="pb-sm mx-md">
                  <ControlledAmountInput
                    className="text-bold"
                    form={form}
                    name={'withdrawalAmount'}
                    label={'Withdrawal amount'}
                    dark
                    inputClass="flex-row-center mt-xs"
                    labelSize="md"
                    disabled={isDisable}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-container-section mt-md">
            <h6>Bank account details</h6>
            <div className="form-container-section bank-details">
              <ControlledRadioMenu
                className="text-bold ml-md pb-xs"
                direction="vertical"
                form={form}
                options={[
                  {
                    label:
                      'Use my bank account details on file for this withdrawal',
                    value: true,
                  },
                  {
                    label: 'Add a new bank account',
                    value: false,
                  },
                ]}
                name={'useExistingBankAccount'}
                disabled={isDisable}
              />
              {!form.values.useExistingBankAccount && (
                <div className="mx-md">
                  <ControlledInput
                    className="text-bold"
                    form={form}
                    name={'bankAccountName'}
                    label={'Bank account name'}
                    dark
                    inputClass="flex-row-center mt-xs"
                    placeholder={'e.g John Smith'}
                    labelSize="md"
                    disabled={isDisable}
                  />
                  <InputWrapper
                    label="Account number"
                    className="pb-md text-bold"
                    error={
                      form.touched.bankAccountNumber &&
                      form.errors.bankAccountNumber
                    }
                  >
                    <BankAccountInput
                      account={
                        form.values.bankAccountNumber &&
                        form.values.bankAccountNumber.split('-').join('')
                      }
                      error={
                        form.touched.bankAccountNumber &&
                        !!form.errors.bankAccountNumber
                      }
                      onChange={(val) => {
                        form.setFieldValue('bankAccountNumber', val)
                      }}
                      onBlur={(e) => {
                        if (
                          !e.relatedTarget ||
                          (e.relatedTarget instanceof Node &&
                            !e.currentTarget.contains(e.relatedTarget))
                        ) {
                          form.setFieldTouched('bankAccountNumber', true, true)
                        }
                      }}
                      separator="-"
                      disabled={isDisable}
                    />
                  </InputWrapper>
                  <p className="text-small color-text-dark mt-0">
                    <em>
                      To protect the security of your account, we may call to
                      verify your withdrawal request. To process your withdrawal
                      we need to have proof of your bank account. If you have
                      not made a previous withrawal, or if your bank account
                      differs from your last withdrawal, please email a copy of
                      your bank statement or a deposit slip to us at{' '}
                      <a
                        className="contact-menu-item email-link"
                        href="mailto:enquiries@fisherfunds.co.nz"
                      >
                        enquiries@fisherfunds.co.nz
                      </a>
                      .
                    </em>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action-buttons my-md">
            <Button
              className="text-regular mx-md "
              variant="filled"
              color="primary"
              size={'sm'}
              type="submit"
              disabled={isDisable}
            >
              {isDisable ? <LoadingSpinner /> : 'Submit'}
            </Button>
            {handleBack && (
              <Button
                className="text-regular mx-md"
                variant="link"
                color="primary"
                size={'sm'}
                onClick={handleBack}
                disabled={isDisable}
              >
                Back
              </Button>
            )}
          </div>
        </form>
      </div>
      <div className="form-disclaimer">
        <p className="text-small color-text-light">
          1. Once we have all required information, payments are usually made
          within 5 working days
        </p>
        {isInvestmentFunds && (
          <p className="text-small color-text-light">
            2. <strong>Trusts and Entities</strong>: Online withdrawals are not
            currently available. To submit a withdrawal for an account owned by
            a Trust or Entity, please complete the{' '}
            <Link
              target="_blank"
              className="text-underline"
              to="https://assets.fisherfunds.co.nz/investment-funds-withdrawals-form"
              size="sm"
            >
              Fisher Funds Investment Funds Withdrawals Form
            </Link>
            .
          </p>
        )}
      </div>
    </>
  )
}

export default WithdrawFundsForm
