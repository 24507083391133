import React, { useMemo, useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MuiLink from '@material-ui/core/Link'
import { Account } from '../../redux/accounts/accounts.model'
import { Button } from '../clickable/button/Button'
import InvestmentStrategySwitch from '../investment-strategy-switch/InvestmentStrategySwitch'
import { getAccountProductSlug } from '../../common/product-helper'
import RenameAccountModal from '../rename-account/RenameAccountModal'
import {
  hasWriteAuthorisation,
  isApexProductAccount,
  isInvestmentFundsAccount,
  isManagedFundProductAccount,
} from '../../common/accounts-helper'
import { UserData } from '../../redux/user/user.model'
import { ProductSlug } from '../../common/product-variables'
import { HASH_MODALS } from '../../common/hash-helper'
import { isApexSwitchEnabled } from '../../common/switch-helper'
import { canWithdrawFunds } from '../../common/withdraw-funds-helper'
import { isAdult } from '../../common/user-helper'

interface AccountDetailsMenuProps {
  currentAccount: Account
  user: UserData
  authToken: string
}

const BUTTON_COLORS_BY_PRODUCT = {
  [ProductSlug.FFMF]: 'white',
  [ProductSlug.FF_PREMIUM]: 'primary',
}

export default function AccountDetailsMenu(props: AccountDetailsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [renameModalOpen, setRenameModalOpen] = useState(false)

  const ENABLE_RENAME = process.env.REACT_APP_ENABLE_RENAME_ACCOUNT === 'true'

  // We plan to replace this logic with field account.canWrite
  const canSwitch = () => {
    const isApexProduct = isApexProductAccount(
      props.currentAccount.productExternalRef
    )
    if (!isApexProduct) {
      return true
    }

    if (!isApexSwitchEnabled) {
      return false
    }

    if (isInvestmentFundsAccount(props.currentAccount)) {
      return (
        isAdult(props.user?.birthDate) &&
        hasWriteAuthorisation(props.currentAccount)
      )
    }

    return true
  }

  const ENABLE_WITHDRAW_FUNDS = canWithdrawFunds(
    props.currentAccount,
    props.user?.birthDate
  )

  const handleShowAccountOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const productSlug = useMemo(
    () => getAccountProductSlug(props.currentAccount) as ProductSlug,
    [props.currentAccount]
  )
  const buttonColor = BUTTON_COLORS_BY_PRODUCT[productSlug] || 'white'

  return (
    <div className="action-buttons">
      <Button
        variant="outlined"
        color={buttonColor}
        iconLeft={MoreHorizIcon}
        aria-controls="account-options-menu"
        aria-haspopup="true"
        onClick={handleShowAccountOptions}
      >
        <span className="desktop">Account options</span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        id="account-options-menu"
        className="account-options-menu"
      >
        {canSwitch() && (
          <MenuItem disableRipple>
            <InvestmentStrategySwitch
              currentAccount={props.currentAccount}
              onClose={handleClose}
              className="color-dusk"
            />
          </MenuItem>
        )}
        <MenuItem disableRipple>
          <MuiLink
            href={`#${HASH_MODALS.ADD_FUNDS}`}
            className="text-decoration-none color-dusk"
            onClick={handleClose}
          >
            Add funds
          </MuiLink>
        </MenuItem>
        {ENABLE_WITHDRAW_FUNDS && (
          <MenuItem disableRipple>
            <MuiLink
              className="text-decoration-none color-dusk"
              href={`#${HASH_MODALS.WITHDRAW}`}
              onClick={handleClose}
            >
              Withdraw funds
            </MuiLink>
          </MenuItem>
        )}
        {ENABLE_RENAME && isManagedFundProductAccount(props.currentAccount) && (
          <MenuItem disableRipple>
            <MuiLink
              onClick={() => setRenameModalOpen(true)}
              underline="none"
              className="text-decoration-none color-dusk"
            >
              Rename account
            </MuiLink>
          </MenuItem>
        )}
        {renameModalOpen && (
          <RenameAccountModal
            userId={props.user.userid}
            account={props.currentAccount}
            authToken={props.authToken}
            onClose={() => setRenameModalOpen(false)}
          />
        )}
      </Menu>
    </div>
  )
}
