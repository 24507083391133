import { Badge, IconButton } from '@material-ui/core'
import { useAtomic } from '../../hooks/useAtomic'
import Modal from '../modal/Modal'
import { NotificationsNoneOutlined } from '@material-ui/icons'
import './NotificationBell.scss'
import { connect, Dispatch } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { calculateAccountTotal } from '../../common/accounts-helper'
import { Account } from '../../redux/accounts/accounts.model'
import { useEffect } from 'react'
import AtomicSDK from '@atomic.io/action-cards-web-sdk'
import { AuthorisationActions } from '../../redux/authorisation/authorisation.actions'
import { bindActionCreators } from 'redux'
import { SetNumUnseenAtomicCardsRequestAction } from '../../redux/atomic-notifications/atomic-notifications.actions'

interface NotificationBellProps {
  notificationOpen: boolean
  userDataLoaded: boolean
  handleBellClick: () => void
  handleBellClose: () => void
  total?: number
  accountErrors?: Account[]
  fscClientID?: string
  authToken?: string
  numUnseenAtomicCards?: number
  setUnseenCards?: typeof SetNumUnseenAtomicCardsRequestAction
  isAtomicInitialised?: boolean
}

const ATOMIC_STREAM_CONTAINER_ID =
  process.env.REACT_APP_ATOMIC_STREAM_CONTAINER_ID

export function NotificationBell(props: NotificationBellProps) {
  const {
    total,
    accountErrors,
    notificationOpen,
    userDataLoaded,
    handleBellClick,
    handleBellClose,
    fscClientID,
    authToken,
    numUnseenAtomicCards,
    setUnseenCards,
    isAtomicInitialised,
  } = props
  const [atomicContainer] = useAtomic(
    total,
    accountErrors,
    fscClientID,
    authToken
  )

  useEffect(() => {
    if (!isAtomicInitialised) {
      return
    }
    AtomicSDK.requestUserMetrics().then((resr) => {
      const cardNum = resr.unseenCardsForStreamContainer(
        ATOMIC_STREAM_CONTAINER_ID
      )
      setUnseenCards(Math.max(cardNum, 0))
    })
  }, [notificationOpen, setUnseenCards, isAtomicInitialised])

  if (!isAtomicInitialised) {
    return null
  }

  return (
    <>
      <Badge badgeContent={numUnseenAtomicCards} color="error" overlap="circle">
        <IconButton
          disabled={!userDataLoaded}
          onClick={handleBellClick}
          className="bell-button"
        >
          <NotificationsNoneOutlined />
        </IconButton>
      </Badge>
      {notificationOpen && (
        <Modal
          title="Your notifications"
          className="notifications-modal"
          open
          onClose={handleBellClose}
        >
          {atomicContainer}
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  const { total } = calculateAccountTotal(state.accounts.accounts)

  return {
    total,
    accountErrors: state.accounts.accountErrors,
    fscClientID: state.user.clientId,
    authToken: state.authorisation.authorisationToken,
    numUnseenAtomicCards: state.atomicNotifications.numUnseenAtomicCards,
    isAtomicInitialised: state.atomicNotifications.isAtomicInitialised,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AuthorisationActions>) => ({
  setUnseenCards: bindActionCreators(
    SetNumUnseenAtomicCardsRequestAction,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(NotificationBell)
