import { AccountsState } from './accounts.model'

import { ReportsRequestActionType } from '../reports/reports.actions'
import { getSimpleProductName } from '../../common/accounts-helper'

export enum actionTypes {
  ACCOUNTS_OVERVIEW_REQUEST = 'ACCOUNTS_OVERVIEW_REQUEST',
  ACCOUNTS_OVERVIEW_REQUEST_SUCCESS = 'ACCOUNTS_OVERVIEW_REQUEST_SUCCESS',
  ACCOUNTS_OVERVIEW_REQUEST_SKIP = 'ACCOUNTS_OVERVIEW_REQUEST_SKIP',
  ACCOUNTS_OVERVIEW_REQUEST_FAILURE = 'ACCOUNTS_OVERVIEW_REQUEST_FAILURE',
  ACCOUNTS_OVERVIEW_REQUEST_NO_ACCOUNTS = 'ACCOUNTS_OVERVIEW_REQUEST_NO_ACCOUNTS',
  ACCOUNTS_OVERVIEW_FUNDS_REQUEST = 'ACCOUNTS_OVERVIEW_FUNDS_REQUEST',
  ACCOUNTS_OVERVIEW_RENAME_SUCCESS = 'ACCOUNTS_OVERVIEW_RENAME_SUCCESS',
}

type AccountsOverviewFundsRequest = {
  userId: string
  nextAction: any
  nextActionParams: any
}

export type AccountsOverviewRequestActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST
  payload: string
}
export type AccountsOverviewRequestSuccessActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SUCCESS
  payload: AccountsState
}
export type AccountsOverviewRequestFailureActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_FAILURE
  payload: string
}
export type AccountsOverviewRequestSkipActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SKIP
}
export type AccountsOverviewRequestNoAccountsActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_NO_ACCOUNTS
  payload: string
}
export type AccountsOverviewFundsRequestActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_FUNDS_REQUEST
  payload: AccountsOverviewFundsRequest
}
export type AccountsOverviewRenameSuccessActionType = {
  type: actionTypes.ACCOUNTS_OVERVIEW_RENAME_SUCCESS
  payload: { accountNumber: string; accountName: string }
}

export const AccountsOverviewRequestAction = (
  userId: string
): AccountsOverviewRequestActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST,
  payload: userId,
})

export const AccountsOverviewRequestSuccessAction = (
  accounts: AccountsState
): AccountsOverviewRequestSuccessActionType => {
  accounts.switchAccounts = []
  accounts.directDebitAccounts = []

  for (let i = 0, ii = accounts.accounts.length; i < ii; i++) {
    if (accounts.accounts[i].switchURL) {
      accounts.switchAccounts.push(accounts.accounts[i])
    }
    if (accounts.accounts[i].directDebitURL) {
      accounts.directDebitAccounts.push(accounts.accounts[i])
    }
    accounts.accounts[i].isDepositMode =
      accounts.accounts[i].status === 'pending' ||
      accounts.accounts[i].accountTotal === 0
    accounts.accounts[i].simpleProductName = getSimpleProductName(
      accounts.accounts[i].productExternalRef
    )
  }
  return {
    type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SUCCESS,
    payload: accounts,
  }
}

export const AccountsOverviewRequestFailureAction = (
  message: string
): AccountsOverviewRequestFailureActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_FAILURE,
  payload: message,
})

export const AccountsOverviewRequestSkipAction = (): AccountsOverviewRequestSkipActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_SKIP,
})

export const AccountsOverviewRequestNoAccountsAction = (
  message: string
): AccountsOverviewRequestNoAccountsActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_REQUEST_NO_ACCOUNTS,
  payload: message,
})

export const AccountsOverviewFundsRequestAction = (
  userId: string,
  nextAction: any,
  nextActionParams: any
): AccountsOverviewFundsRequestActionType | ReportsRequestActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_FUNDS_REQUEST,
  payload: {
    userId,
    nextAction,
    nextActionParams,
  },
})

export const AccountsOverviewRenameSuccessAction = (
  accountNumber: string,
  accountName: string
): AccountsOverviewRenameSuccessActionType => ({
  type: actionTypes.ACCOUNTS_OVERVIEW_RENAME_SUCCESS,
  payload: { accountNumber, accountName },
})

export type AccountsActions =
  | AccountsOverviewRequestActionType
  | AccountsOverviewRequestSuccessActionType
  | AccountsOverviewRequestFailureActionType
  | AccountsOverviewRequestNoAccountsActionType
  | AccountsOverviewFundsRequestActionType
  | AccountsOverviewRequestSkipActionType
  | AccountsOverviewRenameSuccessActionType
