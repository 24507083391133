import {
  IInvestmentOption,
  getIsCustomStrategy,
} from '../../utils/investmentOption'
import InvestmentMixAssets, {
  Asset,
} from '../investment-mix/InvestmentMixAssets'
import { StrategyFundAllocation } from '../switch-contentful-modal/SwitchContentfulModal.types'

const mapAllocationToAsset = ({
  fund,
  allocationPercentage,
}: StrategyFundAllocation): Asset => ({
  name: fund.shortName,
  percentage: allocationPercentage,
})

export type CustomInvestmentProps = {
  isCustom?: boolean
  allocations: StrategyFundAllocation[]
  futureAllocations?: StrategyFundAllocation[]
}
export type CustomInvestmentOptionDetailsProps = {
  investmentOption: CustomInvestmentProps | IInvestmentOption
  isCurrent?: boolean
}

enum CUSTOM_INVESTMENT_TYPE {
  CUSTOM_STRATEGY = 'CUSTOM_STRATEGY',
  MIX_OF_FUNDS = 'MIX_OF_FUNDS',
}

const CustomInvestmentOptionDetails = ({
  investmentOption,
  isCurrent,
}: CustomInvestmentOptionDetailsProps) => {
  const {
    allocations,
    futureAllocations,
  } = investmentOption as CustomInvestmentProps
  const hasFutureAllocations = futureAllocations?.length

  const { CUSTOM_STRATEGY, MIX_OF_FUNDS } = CUSTOM_INVESTMENT_TYPE
  const investmentType = getIsCustomStrategy(
    investmentOption as IInvestmentOption
  )
    ? CUSTOM_STRATEGY
    : MIX_OF_FUNDS

  const mixOfFundsAllocation = () =>
    `Your ${isCurrent ? 'current' : 'new'} mix of funds`

  const multipleAllocations =
    allocations?.length > 1 || futureAllocations?.length > 1

  return (
    <div className="allocation-details">
      {investmentType === CUSTOM_STRATEGY && (
        <>
          <p
            className="mt-0 text-large text-bold"
            data-test="current-allocations"
          >
            {!hasFutureAllocations
              ? 'Your strategy'
              : isCurrent
              ? 'Your current strategy'
              : 'Your new custom strategy'}
          </p>
          {!!allocations?.length && (
            <>
              <p className="color-text-light">Current Balance</p>
              <InvestmentMixAssets
                assets={allocations.map(mapAllocationToAsset)}
                showLineGraph={multipleAllocations}
              />
            </>
          )}
        </>
      )}
      {!!hasFutureAllocations && (
        <>
          {investmentType === MIX_OF_FUNDS ? (
            <p data-test="future-allocations">{mixOfFundsAllocation()}</p>
          ) : (
            <p className="color-text-light" data-test="future-allocations">
              Future Investments
            </p>
          )}
          <InvestmentMixAssets
            assets={futureAllocations.map(mapAllocationToAsset)}
            showLineGraph={multipleAllocations}
          />
        </>
      )}
    </div>
  )
}

export default CustomInvestmentOptionDetails
