import Modal from '../modal/Modal'
import CopyableCard from '../copyable-card/CopyableCard'
import { AppState } from '../../redux/app-state'
import { connect } from 'react-redux'
import './AddFundsModal.scss'
import Link from '../clickable/link/Link'
import { ReactComponent as IconDownload } from '../../assets/images/icons/icon_download.svg'
import { BANK_DETAILS } from '../../common/product-helper'
import {
  getAccountByID,
  isApexProductAccount,
  isInvestmentFundsAccount,
  isKiwisaverAccount,
  isKiwiSaverPlanAccount,
} from '../../common/accounts-helper'
import { Notification } from 'shared'
import { getStringBetween } from '../../utils/strings'
import { Account } from '../../redux/accounts/accounts.model'
import {
  lastContributionDay,
  isApproachingContributionDeadline,
} from '../accounts-overview/useGovernmentContribution'

export type AddFundsModalProps = {
  onClose: () => void
  accountId: string
  productExternalName: string
  externalRef: string
}

export type AddFundsModalPropsFromRedux = {
  surname: string
  initials: string
  ird: string
  account: Account
}

export const LateKiwisaverTopUpNote = () => {
  return (
    <>
      <p className="text-bold">
        {`Topping up after ${lastContributionDay.format('DD MMMM')}`}
      </p>
      <p>
        {`To make sure your payment reaches us in time for the 30 June government
        contribution deadline, after ${lastContributionDay.format('DD MMMM')}
         it’s best to top up your account through Inland Revenue.`}
      </p>
      <p>
        You can use the <strong>"Pay tax"</strong> function to top up your
        KiwiSaver account directly from your internet banking. Just choose{' '}
        <strong>"KSS KiwiSaver member account"</strong> as the tax type. If you
        top up this way you won’t need to include your Fisher Funds account
        number – Inland Revenue will allocate your contribution directly to your
        KiwiSaver account using your IRD number.
      </p>
    </>
  )
}

export const AddFundsModal = ({
  onClose,
  accountId,
  externalRef,
  initials,
  ird,
  surname,
  productExternalName,
  account,
}: AddFundsModalProps & AddFundsModalPropsFromRedux) => {
  const SHOW_LATE_TOP_UP_NOTIFICATION =
    isApproachingContributionDeadline() && isKiwisaverAccount(account, false)
  const { bankAccount, debitLink } = BANK_DETAILS[externalRef]
  const isApex = isApexProductAccount(externalRef)

  const renderDirectDebitInstructions = () => {
    if (isApex) {
      return (
        <>
          To set up a direct debit just download and complete the form below. To
          amend or cancel an existing direct debit, please{' '}
          <a
            href="http://fisherfunds.co.nz/support/"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us
          </a>
          .
        </>
      )
    }
    return (
      <>To set up a direct debit just download and complete the form below: </>
    )
  }

  return (
    <>
      <Modal
        className="add-funds__modal"
        title="Add funds to your account"
        subtitle={accountId}
        open
        onClose={onClose}
      >
        <div>
          {SHOW_LATE_TOP_UP_NOTIFICATION && (
            <Notification>
              <LateKiwisaverTopUpNote />
            </Notification>
          )}
          <h5 className="color-midnight">Internet Banking</h5>
          <p>
            Internet banking is the fastest way to add funds to your account.
            Just visit your online banking to make a payment and search for "
            <strong>{productExternalName}</strong>" as the Bill Payee or enter
            the following <em>Account Number</em>:
          </p>
          <CopyableCard
            subTitle="Fisher Funds bank account"
            value={bankAccount}
          />
        </div>
        <p>Please enter the details below:</p>
        <div className="add-funds-details__row">
          {isKiwiSaverPlanAccount(account) ||
          isInvestmentFundsAccount(account) ? (
            <>
              <CopyableCard
                subTitle="Particulars"
                value={ird ?? 'Your IRD number'}
              />
              <CopyableCard subTitle="Code" value={surname} />
            </>
          ) : (
            <>
              <CopyableCard subTitle="Particulars" value={surname} />
              <CopyableCard subTitle="Code" value={initials} />
            </>
          )}
          <CopyableCard subTitle="Reference" value={accountId} />
        </div>
        <hr />
        <h5 className="color-midnight">Direct Debit</h5>
        <p>
          Alternatively you can set up a direct debit from your bank account.{' '}
          {renderDirectDebitInstructions()}
        </p>
        <Link
          className="pt-sm"
          target="_blank"
          rel="noreferrer noopener"
          to={debitLink}
        >
          Set up a direct debit <IconDownload />
        </Link>
        {!isApex && (
          <>
            <Link
              className="pt-sm"
              target="_blank"
              rel="noreferrer noopener"
              to={
                'https://assets.fisherfunds.co.nz/direct-debit-amendment-cancellation-form'
              }
            >
              Amend or cancel a direct debit <IconDownload />
            </Link>
          </>
        )}
      </Modal>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  const { pathname } = state.routing.locationBeforeTransitions
  const accountId = getStringBetween(pathname, '/accounts/', '/')
  return {
    surname: state.user.surname,
    initials: `${state.user.firstName
      .charAt(0)
      .toLocaleUpperCase()}${state.user.surname.charAt(0).toLocaleUpperCase()}`,
    ird: state.user.ird,
    account: getAccountByID(accountId, state.accounts.accounts),
  }
}

export default connect(mapStateToProps)(AddFundsModal)
