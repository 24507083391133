import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { CustomStrategyBar, CustomStrategyBarProps } from './CustomStrategyBar'
import { StrategyFundAllocation } from '../SwitchContentfulModal.types'
import { Button, ButtonProps } from '../../clickable/button/Button'
import { Notification } from 'shared'
import CustomIcon from '../../../common/CustomIcon'
import './CustomStrategySelector.scss'

export type CustomStrategyProps = {
  className?: string
  strategyFundAllocations: StrategyFundAllocation[]
  onChange: (funds?: Pick<FundAllocation, 'id' | 'ratio'>[]) => void
  submitButtonAttr?: Partial<ButtonProps> & { text?: string }
  lite?: boolean
  incrementSize: number
  initialFundAllocation: FundAllocation[]
} & Pick<CustomStrategyBarProps, 'onMoreInfoClick'>

export type FundAllocation = {
  id: string
  name: string
  ratio: number
}

const min = 0
const max = 100

export const CustomStrategySelector = ({
  strategyFundAllocations,
  onChange,
  submitButtonAttr,
  className,
  lite = false,
  incrementSize,
  initialFundAllocation,
  ...barProps
}: CustomStrategyProps) => {
  const hasSuspendedStrategies = useMemo(() => {
    return strategyFundAllocations.some(
      ({ fund: { legacy, isSuspended } }) => !legacy && isSuspended
    )
  }, [strategyFundAllocations])

  const initAllocated = useMemo(() => {
    return initialFundAllocation.reduce((sum, acc) => sum + acc.ratio, 0)
  }, [initialFundAllocation])

  const [fundAllocations, setFundAllocations] = useState(initialFundAllocation)
  const [totalAllocated, setTotalAllocated] = useState(initAllocated)

  useEffect(() => {
    onChange(fundAllocations)
  }, [fundAllocations, onChange])

  const handleChange = (allocation: FundAllocation) => {
    const newAllocations =
      fundAllocations.length === 2
        ? fundAllocations.map((value) => {
            if (value.id === allocation.id) {
              return allocation
            }
            return {
              ...value,
              ratio: max - allocation.ratio,
            }
          })
        : fundAllocations.map((value) => {
            if (value.id === allocation.id) {
              if (totalAllocated + allocation.ratio - value.ratio > max) {
                const newAllocation = {
                  ...value,
                  ratio: value.ratio + max - totalAllocated,
                }
                setTotalAllocated(max)
                return newAllocation
              }
              setTotalAllocated(totalAllocated + allocation.ratio - value.ratio)
              return allocation
            }
            return value
          })
    setFundAllocations(newAllocations)
  }

  const handleReset = () => {
    setFundAllocations(initialFundAllocation)
    setTotalAllocated(initAllocated)
  }

  return (
    <div
      className={cn('custom-strategy-selector', {
        'custom-strategy-selector--lite': lite,
        [`${className}`]: !!className,
      })}
      data-cy="custom-strategy-selector"
    >
      {hasSuspendedStrategies && (
        <Notification className="suspended-alert" type="warning">
          Some funds are suspended until further notice.
        </Notification>
      )}
      <div>
        {fundAllocations.map((allocation: FundAllocation) => (
          <CustomStrategyBar
            allocation={allocation}
            min={min}
            max={max}
            disableMax={totalAllocated === 100}
            key={allocation.id}
            onChange={handleChange}
            lite={lite}
            stepIncrement={incrementSize}
            {...barProps}
          />
        ))}
      </div>
      <div className="flex-row-center">
        <Button
          className="reset-button"
          variant="link"
          iconLeft={() => <CustomIcon icon="refresh" />}
          onClick={handleReset}
        >
          Reset
        </Button>
        <span className="color-lake ml-auto">Total {totalAllocated}%</span>
      </div>
    </div>
  )
}

export default CustomStrategySelector
