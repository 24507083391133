import React from 'react'
import { Link } from 'react-router'
import './HeaderAccountsTabs.scss'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Currency from '../currency/Currency'
import { Account } from '../../redux/accounts/accounts.model'
import { AccountsActions } from '../../redux/accounts/accounts.actions'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../../redux/app-state'
import { LayoutChangeActiveAccountTab } from '../../redux/layout/layout.actions'
import { calculateAccountTotal } from '../../common/accounts-helper'

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

interface AccountsProps {
  total?: number
  isLoading?: boolean
  hasError?: boolean
  errorMessage?: string
  allAccounts?: Account[]
  noAccountsMessage?: string
  activeAccountTab?: string
  setActiveAccountTab: (value: string) => any
}

export function HeaderAccountsTabs(accountProps: AccountsProps) {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    accountProps.setActiveAccountTab(newValue)
  }

  const TabContent = (
    ref: any,
    props: any,
    label: string,
    accountNumber: string,
    total: number
  ) => {
    const accountNumberPath: string =
      accountNumber !== '' ? `/${accountNumber}` : ''
    const accountNumberLabel: string =
      accountNumber !== '' ? ` – ${accountNumber}` : ''
    const path: string = `/accounts${accountNumberPath}`

    return (
      <Link
        title={`${label}${accountNumberLabel}`}
        to={path}
        {...props}
        ref={ref}
      >
        <div
          className={
            'MuiTab-wrapper' + (accountProps.isLoading ? ' loading' : '')
          }
        >
          <span className="tab-label">{label}</span>
          <strong className="tab-value">
            {total >= 0 ? <Currency value={total} /> : '-'}
          </strong>
        </div>
      </Link>
    )
  }

  const renderAccountSummaryTab = () => {
    const MyTab = React.forwardRef((props, ref) =>
      TabContent(ref, props, 'Accounts Summary', '', accountProps.total)
    )

    return (
      <Tab
        className="account-summary-tab"
        {...a11yProps(0)}
        value={'0'}
        disableRipple
        component={MyTab}
      />
    )
  }

  const renderLoadingAccounts = () => {
    if (!accountProps.isLoading) {
      return null
    }

    const MyTab = React.forwardRef((props, ref) =>
      TabContent(ref, props, '-', '/accounts', 0)
    )
    return <Tab {...a11yProps(0)} disableRipple component={MyTab} />
  }

  const activeAccountTab: any = accountProps.activeAccountTab
    ? accountProps.activeAccountTab
    : false

  return (
    <Tabs
      value={activeAccountTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
    >
      {renderAccountSummaryTab()}
      {accountProps.allAccounts.map((account: Account, index) => {
        const MyTab = React.forwardRef((props, ref) =>
          TabContent(
            ref,
            props,
            account.accountName,
            account.accountNumber,
            account.accountTotal
          )
        )

        return (
          <Tab
            key={'tab-' + index}
            value={account.accountNumber}
            {...a11yProps(index + 1)}
            disableRipple
            component={MyTab}
          />
        )
      })}
      {renderLoadingAccounts()}
    </Tabs>
  )
}

const mapStateToProps = (state: AppState) => {
  const { total, allAccounts } = calculateAccountTotal(state.accounts.accounts)

  return {
    isLoading: state.accounts.isLoading,
    hasError: state.accounts.hasError,
    errorMessage: state.accounts.errorMessage,
    noAccountsMessage: state.accounts.noAccountsMessage,
    allAccounts,
    total,
    activeAccountTab: state.layout.activeAccountTab,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountsActions>) => ({
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(HeaderAccountsTabs)
