import { UIEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import ReactSAlert from 'react-s-alert'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {
  calculateAccountTotal,
  hasInvestmentFundsAccount,
  hasKiwiSaverOneAccount,
} from '../../common/accounts-helper'
import { AccountsActions } from '../../redux/accounts/accounts.actions'
import {
  Account,
  CustomerNotification,
} from '../../redux/accounts/accounts.model'
import { AppState } from '../../redux/app-state'
import Currency from '../../components/currency/Currency'
import FFMAlert from '../../components/ffm-alert/FFMAlert'
import AccountsOverview from '../../components/accounts-overview/AccountsOverview'
import NextBestAction from '../../components/next-best-action/NextBestAction'
import {
  hasOwnKiwiSaverAccount,
  hasManagedFundsAccount,
  hasAccountThatWillRebalance,
} from '../../common/accounts-helper'
import {
  storage,
  DISMISSED_NOTIFICATIONS_STORAGE_KEY,
} from '../../services/storage.service'
import { UserData } from '../../redux/user/user.model'
import ProfileQuickActions from '../../components/profile-quick-actions/ProfileQuickActions'
import {
  LayoutChangeActiveAccountTab,
  LayoutChangeActiveMenu,
  LayoutChangeShowHeader,
} from '../../redux/layout/layout.actions'
import { Notification } from 'shared'
import Link from '../../components/clickable/link/Link'
import { UsernameRequestAction } from '../../redux/username/username.actions'
import './Accounts.scss'

export interface AccountsProps {
  getUsernameAlias: (payload: {
    userid: string
  }) => typeof UsernameRequestAction
  setActiveMenu?: (name: string) => void
  changeShowHeader: (show?: boolean) => void
  total: number
  isLoading: boolean
  hasError: boolean
  errorMessage: string
  userid: string
  username: string
  isChangeable: boolean
  allAccounts: Account[]
  accountErrors: Account[]
  customMessageAccounts: string[]
  notifications: CustomerNotification[]
  noAccountsMessage: string
  hasOwnKiwiSaver: boolean
  hasManagedFunds: boolean
  hasInvestmentFunds: boolean
  hasKiwiSaverOne: boolean
  willRebalance: boolean
  birthDate: string
  firstName: string
  user: UserData
  setActiveAccountTab: (value: string) => void
}

export const Accounts = (props: AccountsProps) => {
  // We are using localStorage to replicate website behaviour, this may change for both in the future
  const [dismissedNotifications, setDismissedNotifications] = useState(
    storage.getItem(DISMISSED_NOTIFICATIONS_STORAGE_KEY) || []
  )

  useEffect(() => {
    if (props.userid && !props.username) {
      props.getUsernameAlias({ userid: props.userid })
    }
    // set menu
    props.setActiveAccountTab('0')
    props.setActiveMenu('accounts')
    props.changeShowHeader(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const filteredNotifications = useMemo(
    () =>
      props.notifications?.filter(
        ({ id }) => !dismissedNotifications?.includes(id)
      ),
    [props.notifications, dismissedNotifications]
  )

  const { isLoading, hasError, total, errorMessage, accountErrors } = props

  useEffect(() => {
    if (!hasError && !!errorMessage) {
      // react-s-alert is deprecated: https://fisherfunds.atlassian.net/browse/FN-583
      ReactSAlert.error(errorMessage, { timeout: 'none' })
    }
  }, [hasError, errorMessage])

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const element = e?.currentTarget
    if (!element) {
      return
    }
    props.changeShowHeader(element.scrollTop > 180)
  }

  const renderTotalInvestment = useCallback(() => {
    if (isLoading) {
      return null
    }

    return (
      <div className="total-investment-block">
        <Typography
          component="h3"
          variant="h6"
          className="investment-header mb-0"
        >
          Your total investment
        </Typography>
        <Typography component="p" variant="h1" className="investment-value">
          {!isLoading && !accountErrors?.length && (
            <>
              <Typography
                component="sup"
                variant="h4"
                className="currency-sign"
              >
                $
              </Typography>
              <Currency format="0,0.00" value={total} className="ml-xs" />
            </>
          )}
          {(!!accountErrors?.length || (!isLoading && !total && hasError)) && (
            <span>$ --.--</span>
          )}
        </Typography>
      </div>
    )
  }, [isLoading, total, hasError, accountErrors])

  const closeNotification = useCallback(
    (id: string) => {
      const alreadyExists = dismissedNotifications?.some(
        (storedId: string) => storedId === id
      )

      if (alreadyExists) {
        // Should never happen.
        return
      }
      const updatedNotifications = [...dismissedNotifications, id]
      setDismissedNotifications(updatedNotifications)
      storage.setItem(DISMISSED_NOTIFICATIONS_STORAGE_KEY, updatedNotifications)
    },
    [dismissedNotifications]
  )

  const notificationsList = useMemo(() => {
    if (isLoading || !filteredNotifications?.length) {
      return null
    }

    return filteredNotifications.map((n) => {
      const actions =
        n.linkUrl && n.linkText ? (
          <Link
            variant="link"
            color="primary"
            iconRight={ArrowForwardIcon}
            to={n.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {n.linkText}
          </Link>
        ) : null

      return (
        <Notification
          key={n.id}
          type={n.type ?? 'info'}
          backgroundColor="white"
          actions={actions}
          onClose={() => closeNotification(n.id)}
        >
          <p className="color-midnight">
            <b>{n.title}</b>
          </p>
          {documentToReactComponents(n.message)}
        </Notification>
      )
    })
  }, [filteredNotifications, isLoading, closeNotification])

  return (
    <>
      <div className="main-content-padding" onScroll={handleScroll}>
        <section>
          {renderTotalInvestment()}
          <ProfileQuickActions />
        </section>

        <div className="notifications-container">
          {/* FFMAlert for global errors. We should replace it with <Notification /> */}
          <FFMAlert stackLimit={3} />
          {notificationsList}
        </div>

        <AccountsOverview
          allAccounts={props.allAccounts}
          isLoading={isLoading}
          user={props.user}
        />

        {!isLoading && (
          <NextBestAction
            hasManagedFunds={props.hasManagedFunds}
            hasOwnKiwiSaver={props.hasOwnKiwiSaver}
            hasInvestmentFunds={props.hasInvestmentFunds}
            birthDate={props.birthDate}
            user={props.user}
          />
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  const {
    accounts,
    customMessageAccounts,
    notifications,
    accountErrors,
  } = state.accounts
  const hasOwnKiwiSaver = hasOwnKiwiSaverAccount(accounts)
  const hasManagedFunds = hasManagedFundsAccount(accounts)
  const hasInvestmentFunds = hasInvestmentFundsAccount(accounts)
  const willRebalance = hasAccountThatWillRebalance(accounts)
  const hasKiwiSaverOne = hasKiwiSaverOneAccount(accounts)

  const { total, allAccounts } = calculateAccountTotal(accounts)

  return {
    userid: state.user.userid,
    birthDate: state.user.birthDate,
    username: state.username.username,
    firstName: state.user.firstName,
    isLoading: state.accounts.isLoading,
    hasError: state.accounts.hasError,
    errorMessage: state.accounts.errorMessage,
    noAccountsMessage: state.accounts.noAccountsMessage,
    accountErrors,
    hasOwnKiwiSaver,
    hasManagedFunds,
    hasInvestmentFunds,
    hasKiwiSaverOne,
    willRebalance,
    allAccounts,
    customMessageAccounts,
    notifications,
    total,
    user: state.user,
    activeMenu: state.layout.activeMenu,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountsActions>) => ({
  getUsernameAlias: bindActionCreators(UsernameRequestAction, dispatch),
  changeShowHeader: bindActionCreators(LayoutChangeShowHeader, dispatch),
  setActiveAccountTab: bindActionCreators(
    LayoutChangeActiveAccountTab,
    dispatch
  ),
  setActiveMenu: bindActionCreators(LayoutChangeActiveMenu, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps, null)(Accounts)
