export enum actionTypes {
  SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST = 'SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST',
  SET_IS_ATOMIC_INITIALISED_REQUEST = 'SET_IS_ATOMIC_INITIALISED_REQUEST',
}

export type SetNumUnseenAtomicCardsRequestActionType = {
  type: actionTypes.SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST
  payload: number
}
export type SetIsAtomicInitialisedRequestActionType = {
  type: actionTypes.SET_IS_ATOMIC_INITIALISED_REQUEST
  payload: boolean
}

export const SetNumUnseenAtomicCardsRequestAction = (
  numUnseenAtomicCards: number
): SetNumUnseenAtomicCardsRequestActionType => ({
  type: actionTypes.SET_NUM_UNSEEN_ATOMIC_CARDS_REQUEST,
  payload: numUnseenAtomicCards,
})

export const SetIsAtomicInitialisedRequestAction = (
  isAtomicInitialised: boolean
): SetIsAtomicInitialisedRequestActionType => ({
  type: actionTypes.SET_IS_ATOMIC_INITIALISED_REQUEST,
  payload: isAtomicInitialised,
})

export type AtomicNotificationsActions = SetNumUnseenAtomicCardsRequestActionType
