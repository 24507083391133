import numeral from 'numeral'

// numeralJs when using Math.floor is rounding down incorrectly.
// e.g. 0.0423 to 4.22% instead of 4.23%
// ref https://fisherfunds.atlassian.net/browse/DCX-2711
// To fix this we add Number.EPSILON. Here's documentation on this value's purpose
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON
export const formatCurrency = (
  value: number | string | undefined,
  format: string
) => {
  const num = numeral(value)
  // numeral won't let us add to null or undefined, but format is fine
  if (num.value() !== 0 && value !== null) {
    num.add(Number.EPSILON) // if value is 0 and we try to format Number.EPSILON it will return NaN
  }
  return num.format(format, Math.floor)
}

export const formatSignedCurrency = (value: number, format: string) => {
  return (value >= 0 ? '+' : '') + formatCurrency(value, format)
}

export const formatPercentage = (value: number) => {
  return new Intl.NumberFormat('en-NZ', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: 'never',
  }).format(value)
}

export const formatWithDecimalPlaces = (
  value: number | string | undefined,
  decimalPlaces: number
) => {
  const format = `0,0.${'0'.repeat(decimalPlaces)}`
  return formatCurrency(value, format)
}
